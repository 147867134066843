<template>
  <div class="our-solutions" >
    <div class="banner-middle" v-show="middleBanner.show">
      <div class="text">
        <img src="../assets/banner-middle-icon.png" alt="" class="icons-img">
        <h1 style="font-weight: 600;margin-bottom: 1rem;">{{middleBanner.title}}</h1>
        <p>{{middleBanner.desc}}</p>
        <router-link to="/get">
          <el-button round type="primary">马上试用 <i class="el-icon-arrow-right"></i></el-button>
        </router-link>
      </div>
      <img :src="middleBanner.img" alt="" class="banner-bg" @load="loadImage">
    </div>
     <TitleInvSubtitle :title="title" v-if="data0.length"/>
     <ul class="solutions-wrapper container-fluid3" v-if="data0.length">
       <li class="solutions-items" v-for="(item, index) in data0" :key="index">
         <router-link :to="'/solutions/'+item.id">
           <img :src="item.img" alt="">
           <p>{{item.title}}</p>
         </router-link>
       </li>
     </ul>
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import { get,imageFile, get2} from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'
import img1 from '../assets/cover1.jpg'
import img2 from '../assets/cover2.jpg'
import img3 from '../assets/cover3.jpg'
import img4 from '../assets/cover4.jpg'
import img5 from '../assets/cover5.jpg'
import img6 from '../assets/cover6.jpg'

export default {
 data(){
    return {
      title: {
        title: '解决方案',
        subtitle: '根据流程和需求，量身定制个性化解决方案'
      },
      languageId: 0,
      bannerDesc: '一个完全可扩展、灵活和安全的操作系统，实现根据客户个性化需求进行快速开发、敏捷定制，满足客户各种深度定制需求',
      waitMore: '',
      data0: [],
      middleBanner: {
        title: '',
        desc: '',
        img: '',
        show: false
      }
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this;
    get('/selectFA.jsp').then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      for(var i=0; i<len; i++){
        let obj= {};
        obj.title = data0[i]['faname']
        obj.img = imageFile(data0[i]['sytp']) //header
        obj.id = data0[i]['glid']
        obj.path = '/solutions/'+data0[i]['glid']
        self.data0.push(obj)
      }
    })
     get('/selectSYTP.jsp').then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      self.middleBanner.title = data0[0]['skt43.skf499']
      self.middleBanner.img = imageFile(data0[0]['skt43.skf501']) 
      self.middleBanner.desc = data0[0]['skt43.skf500'] 
    })
    // get2('/get_value.jsp',{lang:0,value:'rHTitle3;rHDesc4;'}).then(function(res){
    //   const data0 = res.data.data;
    //   const title = getQueryString(data.data.data[0].result)
    // })
  },
  methods:{
    loadImage() {
      this.middleBanner.show = true
    }
  }
}
</script>

<style lang="scss">
.our-solutions{
  background: #f8f8f8;
  //padding-bottom: 3rem;
  .banner-middle{
    width: 100%;
    position: relative;
    .text{
      position: absolute;
      text-align: center;
      width: 100%;
      top: 65%;
      transform: translateY(-70%);
      color: #fff;
      .icons-img{
        margin-bottom: 0.5rem;
      }
      h1{
        font-size: 2rem;
      }
      p{
        font-size: 1rem;
        margin-top: 1rem;
        font-weight: normal;
        width: 35rem;
        margin: auto;
      }
      .el-button{
        margin-top: 1.5rem;
        font-size:1rem;
        border-radius: 1.2rem;
      }
    }
    .banner-bg{
      width: 100%;
    }
  }
  .solutions-wrapper{
    padding-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    .solutions-items{
      box-sizing: border-box;
      width: 31.3%;
      background: #fff;
      margin: 0.5% 1% 1rem 1%;
      // display: inline-block;
      text-align: center;
      overflow: hidden;
      img{
        width: 100%;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        &:hover{
         opacity: 0.9;
         cursor: pointer;
        }
        
      }
      p{
        line-height: 1.6;
        font-size: 0.8rem;
        padding: 0.6rem 0.5rem 0.8rem 0.5rem;
      }
    }
  }
}

</style>