<template>
  <div class="products-item" >
    <div class="">
       <el-image-viewer
           v-if="showViewer"
           @close="closeViewer"
           :url-list="urlList" />
      <div class="top-content" v-if="imgtopbg.length">
        <div class="container-fluid3">
          <h2 class="title">{{info.title}}</h2>
          <p class="desc">{{info.desc}}</p>
          <p class="subtitle">{{info.subtitle}}</p>
        </div>
        <div class="img-content" id="topSwitch">
          <swiper class="logo myswiper" 
          :watchSlidesProgress="true"
          :autoplay="true"
          :loop="true"
          :roundLengths="true"
          :centeredSlides="true"
          :slidesPerView="2" 
          @progress="onProgress" @setTransition="onsetTransition"> 
            <swiper-slide v-for="(item, index) in imgtopbg" :key="index">
              <img :src="item" alt="" @click="bigImage(index)">
            </swiper-slide>
          </swiper>
          <img src="../assets/pro-detail-bg.png" alt="" class="top-bg">
        </div>
      </div>
      <div class="product-function container-fluid3">
        <TitleInvSubtitle :title="title"/>
        <ul class="wrapper">
          <li v-for="(item, index) in functions" :key="index">
            <img :src="item.icon">
            <div>
              <h4>{{item.title}}</h4>
              <p>{{item.desc}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="product-detail container-fluid3" v-show="detailPro.length">
      <TitleInvSubtitle :title="{'title': '功能介绍'}"/>
      <el-tabs v-model="activeName"  @tab-click="handleClick" type="card" id="fixed-nav" stretch>
        <el-tab-pane v-for="(item, index) in detailPro" :name="'tab'+index" :key="index" :label="item[0].title" class="container-fluid-fixed">
          <div>
            <span><img src="../assets/list-type-double-circle.png" alt="" style="vertical-align: -14%;margin-right:10px">{{item[0].title}}</span>
            <i class="el-icon-arrow-down" style="font-weight:bold;float:right;"></i>
          </div>
          <div  v-for="(item2,index2) in item[0].item" :key="index2" style="width: 100%">
            <div class="tab-pane-cotent">
              <div class="item-image">
                <!-- <img src="../assets/computer-btn.png" class="computer-btn"> -->
                <!-- <img src="../assets/product-frame.png" alt="" class="bg"> -->
                <img :src="bindimageFile(item2.img)" alt="" :class="['proimg', item2.img1?'':'width-100']" >
                <img :src="bindimageFile(item2.img1)" class="mobile-proimg" v-if="item2.img1">
              </div>
              <div class="nav-item-list">
                <ul>
                  <li v-for="(item3,index3) in item2.text" class="active" :key="index3">
                    <div class="item">
                      <h4>{{item3.subtitle}}</h4><p>{{item3.subcontent}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="product-detail-m container-fluid3" v-if="detailPro">
      <TitleInvSubtitle :title="{'title': '功能介绍'}"/>
      <ul class="layer1" v-for="(item, index) in detailPro" :key="index">
        <li class="layer1-li">
          <h5 class="layer1-title">{{item[0].title}}</h5>
          <ul class="layer2" v-for="(item2, index2) in item[0].item" :key="index2">
            <li class="layer2-li">
              <img :src="bindimageFile(item2.img)" alt="">
            </li>
            <div class="text-container">
              <div class="text" v-for="(item3, index3) in item2.text" :key="index3">
                <h6>{{item3.subtitle}}</h6>
                <p>{{item3.subcontent}}</p>
              </div>
            </div>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import ProThumb from './ProThumb.vue'
import getQueryString from '../plugins/getQueryString.js'
import domain from '../plugins/getDomainName'
import { Swiper, SwiperSlide } from 'swiper/vue';
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import img1 from '../assets/资产.png'
import img2 from '../assets/资产2.png'
import img3 from '../assets/资产3.png'

import icon1 from '../assets/h-icon01.png'
import icon2 from '../assets/h-icon02.png'
import icon3 from '../assets/h-icon03.png'
import icon4 from '../assets/h-icon04.png'
import icon5 from '../assets/h-icon05.png'
import icon6 from '../assets/h-icon06.png'
import icon7 from '../assets/h-icon10.png'
import icon8 from '../assets/h-icon11.png'
import icon9 from '../assets/h-icon12.png'

import proImg1 from '../assets/product1.png'
import proImg2 from '../assets/product2.png'


export default {
  data(){
    return{
      showViewer: false,
      urlList: [],
      systemId:'',
      infoMenu:[],
      navBarFixed: false,
      scroolI: 0,
      title: {title: '', subtitle:''},
      imgtopbg: [],
      activeName: "tab0",
      navActive: '1',
      activeProImg: '',
      mainImg: '',
      info:{
        title: '',
        desc: '',
        subtitle: ''
      },
      functions:[],
      icon:[icon1,icon2,icon3,icon4,icon5,icon6,icon7,icon8,icon9],
      parameter:[],
      relativeProducts: [],
      languageId: '',
      titleParams: [],
      path: '',
      detailPro:[]
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    TitleInvSubtitle,
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  unmounted() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    bindimageFile(file){
      if(!file){
        return '';
      }else {
        const file0 = file.split(';')
        return  'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&domainid=3&fid='+file0[0]+'&filename='+file0[0];
      }
    },
    bigImage(index){
      
      this.showViewer = true
      let tempImgList = this.imgtopbg 
      let temparr1 = tempImgList.slice(0,index)
      let temparr2 = tempImgList.slice(index, tempImgList.length+1)
      this.urlList = temparr2.concat(temparr1)
      //console.log(this.imgtopbg)
    },
    closeViewer() {
      this.showViewer = false
    },
    // 切换图片 index为图片下标值
    onSwitch(index) {
      console.log(index)
    },
    onProgress(swiper,progress){
      //console.log(progress)
      //console.log(swiper)
      const width = document.getElementById('topSwitch').offsetWidth;
      for (var i = 0; i < swiper.slides.length; i++) {
				var slide = swiper.slides.eq(i);
        var slideProgress = swiper.slides[i].progress;
        var modify = 1;
				if (Math.abs(slideProgress) > 1) {
					modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
				}
				var translate = slideProgress * modify * Math.ceil(width/3) + 'px';
				var scale = 1 - Math.abs(slideProgress) / 5;
				var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
				slide.transform('translateX(' + translate + ') scale(' + scale + ')');
				slide.css('zIndex', zIndex);
				slide.css('opacity', 1);
        //slide.css('width', "600px");
				if (Math.abs(slideProgress) > 2) {
					slide.css('opacity', 0);
				}
			}
    },
    onsetTransition(swiper, transition){
      for (var i = 0; i < swiper.slides.length; i++) {
				var slide = swiper.slides.eq(i)
				slide.transition(transition);
			}
    },
    goScroll(paneName) {
      //const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTopPoint = document.querySelector(`#pane-${paneName}`).offsetTop;
      const fixedTop = document.querySelector('#fixed-nav').offsetTop
      window.scrollTo(0, offsetTopPoint+fixedTop+2);
    },
    handleClick(tab,event){
      console.log(tab.paneName, event)
      this.goScroll(tab.paneName)
    },
    changeItem(index){
      this.navActive = index
      if(index==1){
        this.activeProImg = proImg2
      }else{
        this.activeProImg = proImg1
      }
      
    },
    watchScroll() {
      // 滚动的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      
      // 容器的高度
     
      var offsetTop = document.querySelector("#fixed-nav>.el-tabs__header").offsetTop
      const fixedHigh = document.querySelector("#fixed-nav").offsetTop
      //console.log(offsetTop)
      //判断window滚动条上下滚动
      const scrollDir = scrollTop - this.scroolI
      this.scroolI = scrollTop;
      //console.log(scrollTop,offsetTop,scrollDir,fixedHigh)
      //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if(scrollDir>0){
        if(scrollTop > offsetTop){
          document.querySelector("#fixed-nav>.el-tabs__header").classList.add("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop='65px'
        }else{
          document.querySelector("#fixed-nav>.el-tabs__header").classList.remove("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop=''
        }
        // this.navBarFixed = scrollTop > offsetTop ? true : false;
      }else{
        if(scrollTop > fixedHigh){
          document.querySelector("#fixed-nav>.el-tabs__header").classList.add("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop='65px'
        }else{
          document.querySelector("#fixed-nav>.el-tabs__header").classList.remove("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop=''
        }
        //this.navBarFixed = scrollTop > fixedHigh ? true : false;
      }
      //设置滚动监听改变activeName el-tab-pane
      const ele = document.getElementsByClassName('el-tab-pane') 
      const fixedTop = document.querySelector('#fixed-nav').offsetTop
      for(var i=0; i<ele.length; i++){
          const eleOffsetTop = document.querySelector(`#pane-tab${i}`).offsetTop;
          if(fixedTop+eleOffsetTop-scrollTop<100&&fixedTop+eleOffsetTop-scrollTop>-20){
            this.activeName = 'tab'+i
          }
      }
     
    },
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    
    this.activeProImg = proImg1;
    
      get('/product_top_content.jsp',{domain:id}).then(function(res){
        const data0 = res.data.data;
        self.info.title = data0[0]['skt16.skf179']
        self.info.subtitle = data0[0]['skt16.skf180']
        self.info.desc = data0[0]['skt16.skf181']
        //console.log(res)
      })
      get('/product_center_title.jsp',{domain:id}).then(function(res){
        const data0 = res.data.data;
        self.title.title = data0[0]['skt18.skf196']
      })
      get('/product_center_content.jsp',{domain:id}).then(function(res){
        const data0 = res.data.data;
        const len = data0.length
        //console.log(res)
        for(var i=0; i<len; i++){
          var obj = {}
          //obj.icon = imageFile(data0[i]['skt19.skf203'])
          obj.icon = imageFile(data0[i]['skt19.skf203'])
          obj.title = data0[i]['skt19.skf204']
          obj.desc = data0[i]['skt19.skf205']
          self.functions.push(obj)
        }
      })
      //获取产品介绍详情标题
      // get('/selectDBT.jsp',{domain:id,v_n:1}).then(function(res){
      //   const data0 = res.data.data;
      //   const len = data0.length
      //   for(var i=0; i<len; i++){
      //     var obj = {}
      //     //obj.icon = imageFile(data0[i]['skt19.skf203'])
      //     obj.id = data0[i]['skt22.skf231']
      //     obj.title = data0[i]['skt22.skf232']
      //     self.infoMenu.push(obj)
      //   }
      // })
      //获取产品介绍详情
      // get('/pro-foot.jsp',{domain:id}).then(function(res){
      //   const data0 =  JSON.parse(res.data.data[0].result)
      //   //console.log(data0)
      //   self.detailPro = data0.map((item, index) => {
      //     item.item.map((item2)=> {
      //       item2.img = imageFile(item2.img)
      //       item2.img1 = imageFile(item2.img1)
      //       return item2
      //     })
      //     return item
      //   })
      //   //console.log(data0)
      // })

      //获取产品介绍详情 拆分数据 分段加载
      //产品介绍记录数 pro_cnt.jsp
      get('./pro_cnt.jsp',{domain:id}).then(function(res){
        const len = res.data.data[0].v_cnt
        //console.log('我是产品介绍记录数'+len) 
        for(var i=0; i<len; i++){
          
          //产品介绍 pro-foot2.jsp
          new Promise((resolve,reject) => {
              get('/pro-foot2.jsp',{domain:id,v_n:i}).then(res => {
                resolve(res)
              },(err) => {
                reject(err)
              })
          }).then(res=>{
              const data0 =  JSON.parse(res.data.data[0].outtext)
              self.detailPro.push(data0)
              //console.log(data0)
          })
        }     
        //console.log(self.detailPro)
      })
      
      

    get('/selectTP.jsp',{domain:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length
      //console.log(data0)
      for(var i=0; i<len; i++){
        self.imgtopbg.push(imageFile(data0[i]['skt42.skf492']))
      }
    })
  }
}
</script>

<style lang="scss">
.products-item{
  background: #fff;
  padding-top: 3rem;
  // padding-bottom: 3rem;
  line-height: 1.5;
  .navBarFixed{
    position: fixed;
    left:0;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  .top-content{
    text-align: center;
    color:#666;
    .title{
      font-size: 2rem;
      color: #222;
      font-weight: normal;
    }
    .desc{
      font-size: 0.8rem;
      max-width: 57.5rem;
      margin: 0 auto;
      padding: 1rem 0;
      line-height: 2;
    }
    .subtitle{
      font-size: 1.2rem;
    }
    .img-content{
      position: relative;
      width: 60rem;
      margin: 3rem auto;
      .top-bg{
        position: absolute;
        left: 0;
        top: 130px;
        width: 100%;
      }
    }
    .swiper-container{
      height: 24rem;
      padding-top: 40px;
    }
    .swiper-container .swiper-wrapper .swiper-slide{
      // transform:scale(0.8);
      background: #fff;
      box-shadow: 0 4px 20px rgba(45,61,86, 0.2);
      border-radius: 10px;
      overflow: hidden;
      width: 600px;
      height: 360px;
      border: 1px solid #efefef;
      cursor: pointer;
      .el-image{
        height: 100%;
      }
      img{
        object-fit: contain;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
  }
  .product-function{
    .wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1rem;
      li{
        width: 28%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 4rem;
        img{
          margin-right: 1.5rem;
        }
        h4{
          font-size: 1rem;
          font-weight: normal;
          margin-bottom: 0.5rem;
        }
        p{
          font-size: 0.7rem;
          color:#666666;
        }
      }
    }
  }
  .product-detail{
    margin-top: 60px;
    background: #F8F9FD;
    padding-bottom: 60px;
    .el-tabs__nav-next, .el-tabs__nav-prev{
      line-height: 3.4rem;
      font-size: 16px;
    }
    .el-tabs--card > .el-tabs__header{
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border: none;
      padding-right: 10%;
      padding-left: 10%;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item{
      border-left: none;
    }
    .el-tabs__item.is-active{
      background-color: #235EB6;
      color: #fff;
    }
    .el-tabs__item{
      // &:focus{
      //   color: #fff;
      // }
      transition: none;
    }
    .el-tabs__nav-wrap{
      background: #F8F9FD;
    }
    .el-tabs__nav-scroll{
      padding: 10px 0;
      background: #F8F9FD;
    }
    .container-fluid-fixed{
      max-width: 60rem;
      margin: 60px auto;
      background: #fff;
      border-radius: 6px;
      padding: 1rem 1rem 2rem 1rem;
    }
    .el-tabs__content{
      margin-top: 2rem;
    }
    .tab-pane-cotent{
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      align-items: center;
      flex-direction: column;
      // &.reverseDir{
      //   flex-direction: row-reverse;
      // }
      .nav-item-list{
        // margin-right: 2rem;
        // width: 35%;
        // flex: 1;
        max-width: 55rem;
        width: 95%;
        ul{
          text-align: center;
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          li.active{
            border-color: #B3D8FF;
            background: #ECF5FF;
          }
          //li+li{
            //margin-left: 1rem;
          //}
          li{
            display: inline-block;
            border: 1px solid #ebeef5;
            background: #fff;
            border-radius: 4px;
            padding: 10px 20px;
            margin-top: 1rem;
            cursor: pointer;
            text-align: left;
            flex: 1;
            &:hover{
              border-color: #B3D8FF;
              background: #ECF5FF;
            }
            .item+.item{
margin-top: 0.8rem;
            }
            .item{
              h4{
                font-size: 0.8rem;
                line-height: 30px;
                font-weight: normal;
                color: #222222;
              }
              p{
                font-size: 0.7rem;
                color: #666666;
              }
            }
          }
        }
      }
      .item-image{
        margin-top: 1rem;
        max-width: 55rem;
        position: relative;
        // background: #EEEDED;
        // border-radius: 10px;
        // padding: 0.5rem;
        width: 95%;
        img{
          image-rendering: -moz-crisp-edges;
          image-rendering: -o-crisp-edges;
          image-rendering: -webkit-optimize-contrast;
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;
        }
        // position: relative;
        .proimg{
          // z-index: 99;
          // position: absolute;
          // left: 0;
          // right: 0;
          // margin: auto;
          // bottom: 5%;
          width: 90%;
          object-fit: scale-down;
          border: 10px solid #EEEDED;
          border-radius: 10px;
          &.width-100{
            width: 100%;
          }
        }
        .mobile-proimg{
          position: absolute;
          width: 19%;
          border: 4px solid #37434F;
          border-radius: 10px;
          position: absolute;
          right: 0;
          bottom: 8px;
        }
        .computer-btn{
          margin-bottom: 0.5rem;
        }
        .bg{
          // width: 100%;
          z-index: 1;
        }
      }
    }
  }
  .product-detail-m{
    background: #F8F9FD;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: none;
    .layer1{
      margin-bottom: 2rem;
      background: #fff;
      border-radius: 10px;
      padding: 1rem;
      .layer1-li{
        h5{
          font-size: 1rem;
          font-weight: normal;
          color:#222;
          margin-bottom: 0.5rem;
          &::before{
            content: "\e787";
            margin-right: 0.5rem;
            font-family: element-icons!important;
            color: #666;
          }
        }
        .layer2{
          margin-top: 1rem;
          .layer2-li{
            margin-top: 1rem;
            margin-bottom: 1rem;
             border: 1px solid #ebeef5;
              background: #fff;
            //  border: 1px solid #B3D8FF;
            //   background: #ECF5FF;
              padding: 10px 10px;
              border-radius: 4px;
            img{
              width: 100%;
            }
           
          }
           .text-container{
              // border: 1px solid #ebeef5;
              // background: #fff;
              margin-top: 0.5rem;
              .text{
                margin-bottom: 0.5rem;
              }
              h6{
                font-size: 0.8rem;
                font-weight: normal;
                color: #222222;
              }
              P{
                font-size: 0.7rem;
                color: #666;
              }
            }
        }
      }
    }
  }
  
  .el-tabs__item{
    font-size: 0.8rem;
    padding: 0 20px;
    height: 2.2rem;
    line-height: 2.4rem;
    border-radius: 1.5rem;
  }
  .el-tab-pane{
    min-height: 30rem;
    display: block !important;
  }
}
</style>