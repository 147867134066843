<template>
<div class="pro-thumb">
   <swiper
    :slides-per-view="2.6"
    :spaceBetween="20"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item,index) in thumb" :key="index"><img :src="item.cover" alt="" class="slide-img"></swiper-slide>

     <!-- <swiper-slide><img src="../assets/details-ex-2.png" alt="" class="slide-img"></swiper-slide>
    <swiper-slide><img src="../assets/details-ex-3.png" alt="" class="slide-img"></swiper-slide>
    <swiper-slide><img src="../assets/details-ex-4.png" alt="" class="slide-img"></swiper-slide> -->
  </swiper>
</div>

</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import SwiperCore, { Navigation, Pagination,EffectFade, Autoplay, EffectCoverflow} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';


// install Swiper modules
SwiperCore.use([Navigation, Pagination,EffectFade, Autoplay, EffectCoverflow]);

export default {
  data(){
    return {
      thumb: []
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  created(){
    let self = this;
    const id = this.$route.params.id;

    get('/RFID-ProductDetail.jsp', {langtype:0,productid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      
      //let arr = []
      for(var i=0; i<len; i++) {
        let obj = {}
        obj.isVedio = data0[i]['isVideo']
        if(!data0[i]['isVideo']){
          obj.cover = imageFile(data0[i]['productimg'])  
          obj.view = imageFile(data0[i]['productimg'])
          self.thumb.push(obj)
        }
        
        //arr[i] = obj
      }
     
     
      console.log(self.thumb)
      //self.thumb = arr
      //self.mainImg = arr[0]
      
    })
  },
  methods: {
    onSwiper(swiper) {
      //console.log(swiper);
    },
    onSlideChange() {
      //console.log('slide change');
    },
  },
}
</script>

<style lang="scss">
.swiper-container{
  width: 100%;
}

.swiper-wrapper{
  .swiper-slide{
    a,img{
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}
.pro-thumb{
  display: none;
  margin-bottom: 1rem;
}

</style>