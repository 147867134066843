<template>
  <div class="nav-mobile clearfix">
    <div class="nav-mobile-vertical" v-show="msgVal">
      <ul class="first-layer">
        <li v-for="(item, index) in navMenu" :key="index" :class="{active: activeItem==item.path}" >
          <div v-if="item.clickable||index==7" >
            <router-link :to="item.path">{{item.title}}</router-link>
          </div>
          <div v-else>
            <div class="layer-head" @click="showSecondLayer(index)" :class="{active: activeItem==item.path}">
              <span>{{item.title}}</span>
              <i :class="submenuShow[index]?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
            </div>
            <template v-if="index==1">
              <ul class="second-layer" v-show="submenuShow[index]">
                <li v-for="(item2, index2) in products" :key="index2" :class="{active: activeItem==item2.path}" >
                  <router-link :to="item2.path" >{{item2.title}}</router-link>
                </li>
              </ul>
            </template>
            <template v-if="index==2">
              <ul class="second-layer" v-show="submenuShow[index]">
                <li v-for="(item2, index2) in solutions" :key="index2" :class="{active: activeItem==item2.path}" >
                  <router-link :to="item2.path" >{{item2.title}}</router-link>
                </li>
              </ul>
            </template>
            <!-- <ul class="second-layer" v-show="submenuShow[index]">
              <li v-for="(item2, index2) in item.submenu" :key="index2" :class="{active: activeItem==item2.path}" >
                <router-link :to="item2.path" >{{item2.title}}</router-link>
              </li>
            </ul> -->
          </div>
        </li>
      </ul>
      <div class='maskin' @click="hideDrop()"></div>
    </div>
  </div>
</template>

<script>
// import {navMenu} from '../plugins/navMenu.js'
import { getProMenu, getSolutions} from '../plugins/navMenu.js'
import { get,imageFile } from '../plugins/request.js'

export default {
  data() {
    return {
      hotline:'',
      // navMenu,
      products: [],
      solutions: [],
      activeItem: '',
      //dropShow: false,
      productDropShow: false,
      submenuShow: [],
      languageId:''
      //activeIndex: '/en/products/5'.replace(/^\/(en|cn)\//g, '\/'),
    };
  },
  props: {
    msgVal: Boolean,
    navMenu: Array
  },
  methods: {
    hideDrop(){
      this.$emit('dropback', false)
      console.log(1)
    },
    showSecondLayer(index){
      this.submenuShow[index] = !this.submenuShow[index]
    }
  },
  created() {
    let path = this.$route.path;
    if(path.match(/\/$/g)&&path!='/'){
      this.activeItem = path.replace(/\/$/,'')
    }else{
      this.activeItem = path
    }
    const self = this;
    const languageId = sessionStorage.getItem('language')
    getProMenu(languageId).then(function(data){
      self.products = data
      console.log(data)
    })
    getSolutions(languageId).then(function(data){
      self.solutions = data
      console.log(data)
    })
  },
}
</script>

<style lang="scss">
.nav-mobile-vertical{
    width: 100%;
    height: 100%;
    .first-layer {
      width: 100%;
      max-height: calc(100% - 3.6rem);   //fixed状态下滚动
      margin-bottom: 0;
      position: fixed;
      font-size: 0.8rem;
      top: 3.5rem;
      left: 0;
      z-index: 99;
      overflow-y: scroll; //fixed状态下滚动
      border-top: 1px solid #dcdfe6;
      a{
        width: 100%;
        display: inline-block;
        background: #fff;
      }
      >li{
        border-bottom: 1px solid #dcdfe6;
      }
      li {
        line-height: 3.6;
        background: #fff;
        &.active{
          a{
            background: #ecf5ff;
          color: #235EB6;
          }
          .el-icon-check {
            display: block;
          }
        }
        &:hover{
          background: #ecf5ff;
          color: #235EB6;
          .layer-head {
            color: #444;
          }
          
        }
        .el-icon-check {
            display: none;
          }
        
        a {
          padding: 0 1rem;
          display: inline-block;
          cursor: pointer;
          width: 100%;
          height: 100%;
          text-decoration: none;
        }
        .layer-head {
          text-decoration: none;
          font-size: 0.8rem;
          cursor: pointer;
          background: #fff;
          padding: 0 1rem;
          -webkit-tap-highlight-color:rgba(0,0,0,0);
        }
        .el-icon {
          float: right;
          font-size: 0.9rem;
          font-weight: bold;
          vertical-align: middle;
          margin-top: 0.9rem;
          margin-right: 0.5rem;
        }
        
        .second-layer {
          li {
            height: 2.4rem;
            line-height: 2.4rem;
            font-size: 0.75rem;
            border-top: none;
            &.active{
              a{
                color: #235EB6;
                background: #ecf5ff;
                opacity: 1;
              }
            }
            a {
              padding: 0 1.5rem;
              cursor: pointer;
              opacity: 0.7;
              background: #fff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover{
                color: #235EB6;
              }
              .el-icon {
                margin-top: 0.7rem;
              }
            }
          }
        }
      }
    }
    .maskin{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

</style>