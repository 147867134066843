<template>
  <div>
     <Header />
     <Banner />
     <MBanner />
     <Platform1 />
     <Platform2 />
     <Platform3 />
     <Platform4 />
     <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Platform1 from '@/components/Platform1.vue'
import Platform2 from '@/components/Platform2.vue'
import Platform3 from '@/components/Platform3.vue'
import Platform4 from '@/components/Platform4.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'platform',
  components: {
    Header,
    Banner,
    Footer,
    Platform1,
    Platform2,
    Platform3,
    Platform4,
    MBanner
  },
}
</script>

<style>

</style>