
import icon1 from '../assets/h_icon_无源.png'
import icon3 from '../assets/h_icon_app.png'
import icon2 from '../assets/h_icon_有源.png'
import icon4 from '../assets/h_icon_高校.png'
import icon5 from '../assets/h_icon_政府.png'
import icon6 from '../assets/h_icon_IT.png'
import icon7 from '../assets/h_icon_仓储.png'
import icon14 from '../assets/h_icon_荣誉资质.png'
import icon8 from '../assets/h_icon_联系我们.png'
import icon9 from '../assets/h_icon_公司介绍.png'
import icon10 from '../assets/h_icon_资产1.png'
import icon11 from '../assets/h_icon_硬件.png'
import icon12 from '../assets/h_icon_系统集成.png'
import icon15 from '../assets/h_icon_公司动态.png'
import icon16 from '../assets/h_icon_行业资讯.png'

import mIcon1 from '../assets/h_icon_file.png'
import mIcon2 from '../assets/h_icon_archive.png'
import mIcon3 from '../assets/h_icon_equipment.png'
import mIcon4 from '../assets/h_icon_asset.png'
import mIcon5 from '../assets/h_icon_project.png'
import mIcon6 from '../assets/h_icon_customer.png'
import mIcon7 from '../assets/h_icon_library.png'
import mIcon8 from '../assets/h_icon_vip.png'
import mIcon9 from '../assets/h_icon_lims.png'
import mIcon10 from '../assets/h_icon_consumables.png'
import mIcon11 from '../assets/h_icon_aftersales.png'
import mIcon12 from '../assets/h_icon_iot.png'

import example from '../assets/h_pro_手持机.png'
import { routes } from '../router/index.js'
import getQueryString from './getQueryString'
import Domain from './getDomainName'

let spath = sessionStorage.getItem('spath');
let language = sessionStorage.getItem('language');

let navMenu= [
  { path: '/',  title: '首页','clickable': true},
  { path: '/products',  title: '产品', 'clickable': false,
    submenu: [
      { path: '/products/hardware', title:'配套硬件', img:icon11 },
      { path: '/products/si', title:'系统集成', img:icon12 }
    ]
  },
  { path: '/solutions',title: '解决方案','clickable': false,
    submenu: []   
  },
  { path: '/platform', title: '技术平台','clickable': true},
  { path: '/service', title: '定制服务','clickable': true},
  { path: '/cases', title: '客户案例','clickable': true},
  
  { path: '/newcenter', title: '新闻中心','clickable': false,
    submenu: [
      { path: '/newcenter/information', title: '行业资讯', img:icon16 },
      { path: '/newcenter/news', title: '公司动态', img:icon15 },
    ]
  },
  //{ path: '/company', title: '关于我们','clickable': false, 修改路径用于系统底部链接跳转
  { path: '/company/about', title: '关于我们','clickable': false,  
    submenu:[
      { path: '/company/about', title: '企业介绍', img:icon9 },
      { path: '/company/certification', title: '荣誉资质', img:icon14 },
      { path: '/company/contact', title: '联系我们', img:icon8 },
    ]
  },
]

let moreProduct = [
  { path: 'doc.chenksoft.com', title:'文件管理系统', desc:'文件集中存储，分权限管理', img: mIcon1},
  { path: 'doc.chenksoft.com', title:'档案管理系统', desc:'自建档案类型，查询借阅', img: mIcon2},
  { path: 'eam.chenksoft.com', title:'设备管理系统', desc:'设备档案，维修、管理', img: mIcon3},
  { path: 'eam.chenksoft.com', title:'资产管理系统', desc:'资产登记、盘点、维修', img: mIcon4},
  { path: 'pms.chenksoft.com', title:'项目管理系统', desc:'分阶段管理系统及结算', img: mIcon5},
  { path: 'crm.chenksoft.com', title:'客户管理系统', desc:'客户管理管理，跟单管理', img: mIcon6},
  { path: 'lib.chenksoft.com', title:'图书管理系统', desc:'管理书刊、读者、借阅', img: mIcon7},
  { path: 'mms.chenksoft.com', title:'会员管理系统', desc:'会员信息管理，会员消费', img: mIcon8},
  { path: 'lims.chenksoft.com', title:'实验室Lims系统', desc:'全方位满足实验室需求', img: mIcon9},
  { path: 'lims.chenksoft.com', title:'耗材管理系统', desc:'提供宏观数据和决策', img: mIcon10},
  { path: 'tms.chenksoft.com', title:'售后管理系统', desc:'售后报修、派单、维修', img: mIcon11},
  { path: 'iot.chenksoft.com', title:'智慧物联', desc:'软硬件一体化解决方案', img: mIcon12},
]

let productMenu = [];
let solutions = [];

import { get,imageFile } from '../plugins/request.js'


export async function getMainMenu(params){
  spath = sessionStorage.getItem('spath')
  return get('/get_value.jsp', params).then(function(res){
   
    const title = getQueryString(res.data.data[0].result)
    
    navMenu[0].title = title.hMenu1
    navMenu[0].path = spath+routes[0].alias  //首页
    navMenu[1].title = title.hMenu9
    navMenu[1].path = spath+routes[2].alias.replace('/:id', '')  //产品
    navMenu[2].title = title.hMenu4
    navMenu[2].path = spath+routes[4].alias.replace('/:id', '')  //解决方案
    navMenu[3].title = title.hMenu2
    navMenu[3].path = spath+routes[1].alias   //平台
    navMenu[4].title = title.hMenu5
    navMenu[4].path = spath+routes[5].alias   //技术服务
    navMenu[5].title = title.hMenu6
    navMenu[5].path = spath+routes[8].alias
    navMenu[5].submenu[0].title = title.hMenu6
    navMenu[5].submenu[0].path = spath+routes[8].alias    //关于我们
    navMenu[5].submenu[1].title = title.hMenu7
    navMenu[5].submenu[1].path = spath+routes[9].alias    //联系我们
    navMenu[6].title = title.hMenu8
    navMenu[6].path = spath+routes[7].alias     //客户案例
    //console.log(navMenu)
    return navMenu
  })
}


//解决方案
export function getSolutions(langtype){
  
  return new Promise((resolve, reject) => {
    let arr =[]
    get('/selectFA.jsp').then(function(res){
      console.log(res.data.data)
      const data0 = res.data.data;
      const len = data0.length;
      for(var i=0; i<len; i++){
        let obj= {};
        obj.title = data0[i]['faname']
        obj.img = imageFile(data0[i]['slt']) //header
        obj.id = data0[i]['glid']
        obj.path = '/solutions/'+data0[i]['glid']
        arr[i] = obj
      }
      resolve(arr)
    })
  })
}


//产品
export function getProMenu(langtype){ 
  return new Promise((resolve,reject) => {
    let arr =[]
    get('/select_pro_id.jsp', {langtype:langtype}).then(function(res){
        const data0 = res.data.data;
        const len = data0.length;
        console.log(data0)
        for(var i =0; i<len; i++){
          var obj= {};
          obj.title = data0[i]['pro_name']
          obj.img = imageFile(data0[i]['image'])
          obj.id = data0[i]['id']
          obj.path = '/products/info/'+data0[i]['id']
          arr[i] = obj
        }
        if(Domain.name!='bi'){
          arr.push(navMenu[1].submenu[0])
          arr.push(navMenu[1].submenu[1])
        }else{
          navMenu[1]['clickable'] = true
          navMenu[1]['path'] = arr[0].path
        }
        resolve(arr)
    })
  })
}

function list (langtype, id){
  return new Promise((resolve,reject) => {
    let arr =[]
    get('/RFID-ProductsList.jsp', {langtype:langtype, categoryid:id}).then(function(res){
      const data1 = res.data.data;
      const len1 = data1.length;
      for(var j=0; j<len1; j++){
        let obj1 = {}
        obj1.number = data1[j]['skt44.skf480'];
        obj1.id = data1[j]['skt44.skf487'];
        obj1.path = spath+ '/products/info/' + data1[j]['skt44.skf487'];
        arr[j] = obj1
      }
      //console.log(id,arr)
      resolve(arr)
    })
  })
}

export async function getProducts(langtype){
  let result01 = await getProMenu(langtype);
  const len = result01.length;
  for(var i=0; i<len; i++){
    result01[i].submenu = await list (langtype,result01[i].id)
  }
  return result01
}

export async function getAllMenu(langtype) {
  getMainMenu({lang: langtype, value: 'hMenu1;hMenu9;hMenu2;hMenu4;hMenu5;hMenu6;hMenu7;hMenu8;'})
  navMenu[2].submenu = await getSolutions(langtype)
  let result01 = await getProMenu();
  const len = result01.length;
  for(var i=0; i<len; i++){
    result01[i].submenu = await list (langtype,result01[i].id)
  }
  navMenu[1].submenu = result01
  return navMenu
}
//getAllMenu(language)

export { navMenu, moreProduct}