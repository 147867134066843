<template>
  <div class="SiView-item container-fluid3">
    <TitleInvSubtitle :title="title"/>
    <el-tabs v-model="activeName"  @tab-click="handleClick" type="card" id="fixed-nav" stretch class="container-fluid-fixed">
      <el-tab-pane v-for="(item, index) in hardwares" :name="'tab'+index" :key="index" :label="item.title" >
        <ul>
          <li :class="['item', {'flex-reverse': index2%2==0?false:true}]" v-for="(item2, index2) in item.item" :key="index2">
            <img :src="item2.img" alt="" class="siwiew-image">
            <div class="text">
              <h2>{{item2.title}}</h2>
              <span>{{item2.text}}</span>
            </div>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { get, imageFile } from '../plugins/request.js'
import TitleInvSubtitle from './TitleInvSubtitle.vue'

export default {
  data(){
    return {
      activeName: 'tab0',
      hardwares:[],
       title: {
        title: '系统集成',
        subtitle: '实现数据在各个系统之间实时高效地共享、流转、同步、交互'
      },
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this;
    get('/pro-xijc.jsp').then(function(res){
      console.log(res.data.data[0].result)
      const data0 =  JSON.parse(res.data.data[0].result)
      
      self.hardwares = data0.map((item, index) => {
        item.item.map((item2)=> {
          item2.img = imageFile(item2.img)
          return item2
        })
        return item
      })
      // console.log(self.hardwares)
    })
  },
  methods: {
    handleClick(){

    },
    watchScroll() {
      // 滚动的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      
      // 容器的高度
     
      var offsetTop = document.querySelector("#fixed-nav>.el-tabs__header").offsetTop
      const fixedHigh = document.querySelector("#fixed-nav").offsetTop
      //console.log(offsetTop)
      //判断window滚动条上下滚动
      const scrollDir = scrollTop - this.scroolI
      this.scroolI = scrollTop;
      //console.log(scrollTop,offsetTop,scrollDir,fixedHigh)
      //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if(scrollDir>0){
        if(scrollTop > offsetTop){
          document.querySelector("#fixed-nav>.el-tabs__header").classList.add("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop='65px'
        }else{
          document.querySelector("#fixed-nav>.el-tabs__header").classList.remove("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop=''
        }
        // this.navBarFixed = scrollTop > offsetTop ? true : false;
      }else{
        if(scrollTop > fixedHigh){
          document.querySelector("#fixed-nav>.el-tabs__header").classList.add("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop='65px'
        }else{
          document.querySelector("#fixed-nav>.el-tabs__header").classList.remove("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop=''
        }
        //this.navBarFixed = scrollTop > fixedHigh ? true : false;
      }
   
    },
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  unmounted() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
}
</script>

<style lang="scss">

  .SiView-item{
    background: #fff url(../assets/bg-jic.png) no-repeat;
    // background-size: contain;
    padding-top: 4rem;
    padding-bottom: 9rem;
    .item {
     text-align: center;
     position: relative;
     .siwiew-image{
       //width: 100%;
    //    image-rendering: -moz-crisp-edges;
    // image-rendering: -o-crisp-edges;
    // image-rendering: -webkit-optimize-contrast;
    // image-rendering: crisp-edges;
    // -ms-interpolation-mode: nearest-neighbor;
     }
      &.flex-reverse{
        flex-direction: row-reverse;
      }
      .text{
        position: absolute;
        text-align: justify;
        width: 23rem;
        background: rgba(255, 255, 255, 0.8);
        border: 2px solid #d0d9f9;
        border-radius: 10px;
        padding: 20px;
        top: 120px;
    left: 100px;
        h2{
          font-size: 1.5rem;
          font-weight: normal;
          margin-bottom: 1rem;
          //color: #235EB6;
        }
        span{
          font-size: 16px;
          color: #666;
          //color: #235EB6;
        }
      }
      
    }
    .title-inv-subtitle{
      margin-bottom: 4rem;
    }
    .navBarFixed{
      position: fixed;
      left:0;
      top: 0;
      z-index: 999;
      width: 100%;
    }
     .el-tabs__nav-next, .el-tabs__nav-prev{
      line-height: 3.4rem;
      font-size: 16px;
    }
    .el-tabs--card > .el-tabs__header{
      border: none;
      //margin-top: 4rem;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border: none;
      padding-right: 10%;
      padding-left: 10%;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item{
      border-left: none;
      font-size: 0.8rem;
      padding: 0 20px;
      height: 2.2rem;
      line-height: 2.4rem;
      border-radius: 1.5rem;
      transition: none;
    }
    .el-tabs__item.is-active{
      background-color: #235EB6;
      color: #fff;
    }
    
    .el-tabs__nav-wrap{
      //background: #F8F9FD;
    }
    .el-tabs__nav-scroll{
      padding: 10px 0;
      //background: #F8F9FD;
    }
    .container-fluid-fixed{
      max-width: 60rem;
      margin: 0 auto;
    }
    .el-tabs__content{
     margin-top: 4rem;
    }
    .el-tab-pane{
      //display: block !important;
    }
  }
</style>