<template>
<div style="height:3.5rem;margin-top: 1rem" id="nav-m-box" class="nav-m-box-mobile" >
  <div :class="{'mheader2':true,'fix-m-nav':navBarFixed}">
    <ul class="nav" id="navmheader">
      <template  v-for="(item,index) in navMenu" :key="index" >
        <li :class="'tab-item tab-item'+item.id" >
          <router-link :to="'/products/'+item.id" active-class="active">
            <img :src="item.img2" alt="">
            <span ref="item22">{{item.title}}</span>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</div>
</template>

<script>
import {navMenu, getProMenu} from '../plugins/navMenu.js'
import icon1 from '../assets/nav-small-0.png'
import icon2 from '../assets/nav-small-1.png'
import icon3 from '../assets/nav-small-2.png'
import icon4 from '../assets/nav-small-3.png'
import icon5 from '../assets/nav-small-4.png'
import icon6 from '../assets/nav-small-5.png'


export default {
  data() {
    return {
      navBarFixed: false,
      scroolI: 0,
      fixedHigh:0,
      navMenu:'',
      languageId: '',
    };
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
    //document.querySelector("#navmheader").scrollTo(100, 0)
  },
  unmounted() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
    
  },
  methods: {
    getCss(element, attr){
      if(element.currentStyle){
          return element.currentStyle[attr];
      }else{
          return window.getComputedStyle(element,null)[attr];
      }
    },
    watchScroll() {
      
      // 滚动的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //导航条到顶部的高度
      const offsetTop = document.getElementsByClassName('nav-m-box-mobile')[0].offsetTop-document.getElementsByClassName('nav-m-box-mobile')[0].offsetHeight
      console.log(offsetTop)
      //判断window滚动条上下滚动
      const scroll = scrollTop - this.scroolI
      this.scroolI = scrollTop;
      this.navBarFixed= scrollTop > offsetTop ? true : false;
      
      //导航栏左右滚动
      const ele = document.getElementsByClassName('active')
      const tab0 = document.querySelector("#navmheader")
      
      //console.log(ele)
      // for(let i=0; i<ele.length; i++) {
      
      //     //console.log(offsetTop3-scrollTop)
      //     this.activeIndex2 = (i+1).toString()
      //     //横线滚动条 左右移动
      //     if(scroll<0){
      //       //console.log('U')
      //       if(i>1){
      //         tab0.scrollTo(ele[i-1].offsetLeft, 0)
      //       }
      //     }else{
      //       //console.log('D')
      //       tab0.scrollTo(ele[i].offsetLeft, 0)
      //     }
      //     return;
      
      // }
    },
    async navMenu2() {
      const languageId = this.languageId
      let menu = await getProMenu(languageId)
      menu[0].img2 = icon1;
      menu[1].img2 = icon2;
      menu[2].img2 = icon3;
      menu[3].img2 = icon4;
      menu[4].img2 = icon5;
      menu[5].img2 = icon6;
      this.navMenu = menu;
    }
  },
  created() {
    this.languageId = sessionStorage.getItem('language')
    this.navMenu2()
  },
  watch:{
    navMenu:function(){
      this.$nextTick(function(){
        const id = this.$route.params.id
        const classname = 'tab-item'+id
        const ele = document.getElementsByClassName(classname)
        /*现在数据已经渲染完毕*/
        const tab0 = document.querySelector("#navmheader")
        tab0.scrollLeft = ele[0].offsetLeft
      })
    }
  }
}
</script>

<style lang="scss">
.fix-m-nav{
  position: fixed;
    top: 3.5rem;
    z-index: 999;
}
.nav-m-box-mobile{
  display: none;
}
.mheader2{
  width: 100%;
  height: 3.5rem;
  background: #262830;
  color: #c6c6c6;
  .nav{
    overflow-x: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    li{
      width: 16.66%;
      height: 100%;
      font-size: 0.8rem;
      text-align: center;
      line-height: 4.2;
      width: initial;
      white-space: nowrap;
      a{
        cursor: pointer;
        color: inherit;
        display: inline-block;
          padding: 0 1rem;
        width: 100%;
        height: 100%;
        &.active{
          background: #235EB6;
          color: #fff;
        }
      }
      img{
        width: 0.8rem;
        margin-right: 0.5rem;
        vertical-align: -4%;
      }
      
    }
  }
  
}
</style>