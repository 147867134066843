<template>
  <div>
     <Header />
     <Banner />
     <MBanner />
     <Certification />
     <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'
import Certification from '@/components/Certification.vue'

export default {
  name: 'platform',
  components: {
    Header,
    Banner,
    Footer,
    MBanner,
    Certification
  },
}
</script>

<style>

</style>