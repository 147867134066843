<template>
  <div class="our-video" v-if="videoPoster.length">
    
    <div class="container-fluid3 video-flex">
      <div class="video-text">
        <h4 class="title">{{activeVideo.title}}</h4>
        <p class='desc'>{{activeVideo.desc}}</p>
        <p class="more"><router-link to="/videos">查看更多 <i class="el-icon-arrow-right"></i> </router-link></p>
        <ul class="video-wrapper">
          <li :class="{'video-items': true, 'active': activeVideo.index==index}" v-for="(item, index) in videoPoster" :key="index" @click="changeVideo(index, item)">
            <img :src="item.poster" alt="">
          </li>
        </ul>
      </div>
      <div class="my-video">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer"
          :palysinline="true"
          :options="playerOptions"></video-player>
      </div>
      <router-link to="/videos" style="text-align: center; margin-top: 2rem;" class="more-mobile"><el-button type="primary" plain>查看更多</el-button></router-link>
     </div>
  </div>
</template>

<script>
import { get,imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'

import icon1 from '../assets/h-icon01.png'
import icon2 from '../assets/h-icon02.png'
import icon3 from '../assets/h-icon03.png'
import icon4 from '../assets/h-icon04.png'
import icon5 from '../assets/h-icon05.png'
import icon6 from '../assets/h-icon06.png'
import icon7 from '../assets/h-icon07.png'
import icon8 from '../assets/h-icon08.png'
import icon9 from '../assets/h-icon09.png'
import cover1 from '../assets/cover1.png'
import cover2 from '../assets/cover2.png'

export default {
 data(){
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      title: {
        title: '解决方案',
        subtitle: ''
      },
      activeVideo: {
        title: '',
        desc: '',
        id: '',
        poster: '',
        index: 0,
        url:''
      },
      playlist:[],
      myPlayer:'',
      myPlayer2:'',
      languageId: 0,
      waitMore: '',
      videoPoster: [],
      icon:[icon1,icon2,icon3,icon4,icon5,icon6,icon7,icon8,icon9]
    }
  },
  mounted() { 
    //setTimeout(this.initVideo(),300);
  },
  created(){
    const self = this;
    get('/get_video.jsp').then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
     //for(var i=0; i<len; i++){
      if(len>0){
        for(var i=0; i<2; i++){     
          const obj = {}
          obj.title = data0[i]['skt25.skf263']
          obj.img = imageFile(data0[i]['skt25.skf270'])
          obj.id = data0[i]['skt25.skf261']
        // obj.desc = data0[i]['video_content']
          obj.desc = data0[i]['skt25.skf269']
          //obj.poster = imageFile(data0[i]['thumb'])
          obj.poster = imageFile(data0[i]['skt25.skf270'])
          obj.url =  data0[i]['skt25.skf264']
          obj.index =  i
          self.videoPoster.push(obj)
        }
        self.activeVideo = self.videoPoster[0]
        self.playerOptions.sources[0].src = self.videoPoster[0]['url']
        self.playerOptions.poster = self.videoPoster[0]['poster']  
      }
    
    })
  },
  methods: {
    changeVideo(index, item){
      this.activeVideo = item
      const self = this;
      self.playerOptions.sources[0].src = self.activeVideo['url']
      self.playerOptions.poster = self.activeVideo['poster']
    }
  }
}
</script>

<style lang="scss">
.our-video{
  padding: 4rem 0;
  .video-flex{
    display: flex;
    justify-content: space-between;
  }
  .video-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 2rem;
    .title{
      font-size: 2rem;
      color: #222;
      font-weight: normal;
    }
    .desc{
      font-size: 1.2rem;
      color:#666;
      font-weight: 200;
      width: 27.5rem;
      line-height: 1.7;
      margin-top: 1rem;
    }
    .more{
      font-size: 0.9rem;
      margin-top: 2rem;
      a{
        cursor: pointer;
        color: #235EB6;
      }
    }
    .video-wrapper{
      margin-top: 2rem;
      .video-items{
        width: 8rem;
        border: 2px solid #D8D8D8;
        display: inline-block;
        margin-right: 1.2rem;
        cursor: pointer;
        img{
          width: 100%;
        }
      }
      .video-items.active{
        border: 2px solid #286ED6;
      }
    }
    
  }
  .more-mobile{
    display: none;
  }
  #myVideo3{
    display: none;
  }
  .my-video{
    width: 100%;
  }
}
.video-js .vjs-big-play-button{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>