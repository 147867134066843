<template>
  <div class="cases-2">
    <ul class="items">
      <li v-for="(item, index) in items" :key="index">
        <img :src="item.logo" alt="" >
      </li>
    </ul>
  </div>
</template>

<script>

import company from '../plugins/company.js'
import logo1 from '../assets/case-feisen.png';  
import logo2 from '../assets/case-sankyo.png';  
import logo3 from '../assets/c-en-03.png';  
import logo4 from '../assets/c-en-04.png';  //none
import logo5 from '../assets/case-jinbin.png';  
import logo6 from '../assets/case-meilejia.png';  
import logo7 from '../assets/case-ppg.png';  
import logo8 from '../assets/case-nidec.png';  
import logo9 from '../assets/case-huvitz.png';  
import logo10 from '../assets/case-w.png';  
import logo11 from '../assets/case-p.png';  
import logo12 from '../assets/c-en-12.png';  
import logo13 from '../assets/case-boke.png';  
import logo14 from '../assets/case-thermo.png';  
import logo15 from '../assets/case-green-valley.png';  
import logo16 from '../assets/case-career.png';  
import logo17 from '../assets/case-tyk.png';  
import logo18 from '../assets/c-en-18.png';  
import logo19 from '../assets/case-yinuo.png';  
import logo20 from '../assets/case-boe.png';  
import logo21 from '../assets/c-en-21.png';  //none
import logo22 from '../assets/c-en-22.png';  
import logo23 from '../assets/case-阿斯.png';  
import logo24 from '../assets/case-gke.png';  
import logo25 from '../assets/case-yena.png';  
import logo26 from '../assets/case-ferrotec.png';  
import logo27 from '../assets/case-sikeluo.png';  
import logo28 from '../assets/case-weber.png';  
import logo29 from '../assets/case-lekaibao.png';  
import logo30 from '../assets/c-en-30.png';  
import logo31 from '../assets/case-xinyue.png';  
import logo32 from '../assets/case-warner.png';  
import logo33 from '../assets/case-hengsi.png'; 

export default {
  name: 'CCaseZh',
  components: {
    
  },
  data() {
    return {
      items: [
        {logo: logo23},{logo: logo24},{logo: logo26},{logo: logo33},{logo: logo27},{logo: logo31},
        { logo: logo1 },{logo: logo2},{logo: logo25},{logo: logo30},{logo: logo3},{logo: logo5},{logo: logo6},{logo: logo7},{logo: logo28},{logo: logo29},
        { logo: logo8 },{logo: logo9},{logo: logo10},{logo: logo11},{logo: logo12},{logo: logo13},{logo: logo14},{logo: logo32},
        { logo: logo15 },{logo: logo16},{logo: logo17},{logo: logo18},{logo: logo19},{logo: logo20}
      ]
    }
  },
  mounted(){
    
  }
}
</script>
<style lang="scss">
.cases-2 {
  .items{
    li{
      width: 19%; 
      margin: 0.5%;
      display: inline-block;
      padding: 1%;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      &:hover{
        box-shadow: 0 0px 13px #dbdde6;
        transition: 0.5s box-shadow;
      }
    }
  }
}

</style>