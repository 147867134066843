<template>
  <div class="products-datails">
    <Header />
    <Banner />
    <MBanner />
    <Information />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Information from '@/components/Information.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    Footer,
    Information,
    MBanner
  },
}

</script>

<style>

</style>