<template>
  <div class="home">
    <Header />
    <Banner />
    <MBanner />
    <OurFunction />
    <OurSolutions /> 
    <OurVideo />
    <Feature />
    <HCases />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import OurFunction from '@/components/OurFunction.vue'
import OurSolutions from '@/components/OurSolutions.vue'
import OurVideo from '@/components/OurVideo.vue'
import HCases from '@/components/HCases.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'
import Feature from '../components/Feature.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    OurFunction,
    OurSolutions,
    OurVideo,
    HCases,
    Footer,
    MBanner,
    Feature
  },
  created: function() {
    
  }
}
</script>
<style lang="scss">

</style>