import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import './plugins/element.js'
//import './plugins/language.js'
import './style/element-variables.scss';
import './style/media.scss';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import videoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'


import installElementPlus from './plugins/element'

import { addFontsize }from './plugins/resetFontSize'

addFontsize()

const app = createApp(App)
installElementPlus(app)
app.config.globalProperties.$video = Video
app.use(videoPlayer)
app.use(router).mount('#app')
