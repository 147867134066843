<template>
  <div class="title-inv-subtitle">
    <h2 class="title">{{title.title}}</h2>
    <p class="subtitle">{{title.subtitle}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: Object
  }
}
</script>

<style lang="scss">
.title-inv-subtitle{
  text-align: center;
  padding: 2rem 0;
  .title{
    font-size: 2rem;
    font-weight: normal;
  }
  .subtitle{
    font-size: 0.8rem;
    margin-top: 1rem;
    color:#666;
  }
}
</style>