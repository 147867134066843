<template>
<div class="container-fluid4 certitication">
  <TitleInvSubtitle :title="title"/>
  <ul class="c-list">
    <li v-for="(item, index) in cImgList" :key="index">
        <el-image 
        :src="item.img" 
        :preview-src-list="previewList">
      </el-image>
      <p>{{item.title}}</p>
    </li>
  </ul>
  <el-pagination v-show="page.pageCount>2"
    background
    layout="prev, pager, next"
    :page-count="page.pageCount"
    page-size="20"
    @current-change="changePage">
  </el-pagination>
</div>
</template>
<script>
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { AREA } from '../plugins/address.js'
import { get2 } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import wordDesktopEn from '../assets/word.png'
import wordDesktopEnM from '../assets/word-m.png'
import wordDesktopFr from '../assets/word-fr.png'
import wordDesktopFrM from '../assets/word-m-fr.png'
import { get, imageFile } from '../plugins/request.js'

import img1 from '../assets/3A.jpg'

export default {
  name: 'ContactZh',
  components: {
    TitleInvSubtitle
  },
  data() {
    return {
      title:{title: '荣誉资质'},
      page:{
        currentPage: 0,
        pageCount: ''
      },
      cImgList:[],
      previewList:[]
    }
  },
  methods: {
     changePage(current){
      //console.log(current)
      this.page.currentPage = 8*(current-1)
      const self = this;
      self.cImgList = []
      self.previewList = []
      get('./select_ryzz.jsp',{number:this.page.currentPage}).then(function(data){
        const data0 = data.data.data;
        const len = data0.length;
        for(var i=0; i<len; i++){
          var obj = {}
          obj.title = data0[i]['skt53.skf613']
          obj.img = imageFile(data0[i]['skt53.skf614'])
          self.cImgList.push(obj)
          self.previewList.push(obj.img)
        }
      })
    },
  },
  created: function(){
    const self = this;
    get('./select_ryzz.jsp').then(function(data){
     const data0 = data.data.data;
     const len = data0.length;
     for(var i=0; i<len; i++){
       var obj = {}
       obj.title = data0[i]['skt53.skf613']
       obj.img = imageFile(data0[i]['skt53.skf614'])
       self.cImgList.push(obj)
       self.previewList.push(obj.img)
     }
    })
    get('./select_zzzy.jsp').then(function(data){
     const data0 = data.data.data;
     self.page.pageCount = data0[0]['ceiling']
    })
  }
}
</script>
<style lang="scss">
.certitication{
  padding-bottom: 4rem;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .c-list{
    text-align: left;
    padding-bottom: 6rem;
    li{
      display: inline-block;
      vertical-align: top;
      width: 23%;
      margin-left: 2%;
      margin-top: 4%;
      // img{
      //   width: 100%;
      // }
      p{
        text-align: center;
        font-size: 0.8rem;
        margin-top: 0.5rem;
      }
    }
  }
}
</style>