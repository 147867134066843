<template>
<div class="service-3 container-fluid3">
  <TitleInvSubtitle :title="title"/>
  <div class="image-text">
    <div class="img-1">
      <img src="../assets/dingzhi03.png" alt="">
    </div>
    <ul class="items flow" >
      <li v-for="item in items" :key="item.index">
        <span>{{ item.index }}</span>
        <div class="text">
          <h2>{{ item.title }}</h2>
        <p>{{ item.description }}</p>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>
<script> 
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import { get2, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'cservice',
  data() {
    return{
      title: {
        title: 'Meticulous Service, Professional Team',
        subtitle: ''
      },
      items: [
        {index: '01', title: '', description: ''},
        {index: '02',title: '', description: ''}
      ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  methods: {
    getValue(params) {
      const self = this;
      get2('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['scTitle5']
       
        for(var i=0;i<2; i++){
          self.items[i].title = title[`sc3Title${i+1}`]
          self.items[i].index = `0${i+1}`
          self.items[i].description = title[`sc3D${i+1}`]
        }
      })
    }
  },
   created: function(){
     const languageId = sessionStorage.getItem('language')
    this.getValue({lang: 0, value: 'scTitle5;sc3Title1;sc3D1;sc3Title2;sc3D2;'})
  },
}
</script>
<style lang="scss">
.service-3 {
  padding-bottom: 2rem;
  padding-top: 2rem;
  font-size: 0.75rem;
  img{
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .image-text {
    .img-1{
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      img {
       width: 100%;
       padding-left: 2%;
       padding-right: 2%;
      }
    }
    ul {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      padding-left: 5%;
      padding-right: 5%;
      li{
        display: flex;
        margin-bottom: 2rem;
        span {
          font-size: 1.8rem;
         font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          color: #1F87E8;
          margin-right: 1rem;
        }
        h2 {
          font-weight: normal;
          text-transform: capitalize;
          margin-bottom: 0.5rem;
        }
        p {
          opacity: 0.7;
        }
        .text{flex:1}
        
      }
    }
  }
}

</style>
