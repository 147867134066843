<template>
  <div class="solutions-item container-fluid2">
    <div :class="['left', newsLength ? '' : 'mid-width']">
     <h2>{{title}}</h2>
     <div v-html="info" ></div>
     <!-- 我是暂时不要的 -->
     <!-- <div class="text-box">
       <h4>面临问题</h4>
       <p>1、管理混乱、账实不相符合<br>
        2、管理流程难以实时跟踪<br>
        3、资产状态、位置无法及时获悉<br>
        4、手工盘点繁琐易错、效率低下</p>
     </div>
     <div class="text-box">
       <img src="https://hz.chenksoft.com:443//upload/image/6497298943392984.jpg" alt="">
       <p class="img-text">（网络架构）</p>
       <img src="https://hz.chenksoft.com:443//upload/image/9759238553271565.png" alt="">
       <p class="img-text">（网络架构）</p>
     </div>
      <div class="text-box">
        <h4>系统优势</h4>
        <div>
          <h5>RFID标签打印</h5>
          <p>RFID电子标签内涵芯片和天线，利用RFID标签打印机在可视化打印的同时将数据内容写入芯片中存取，实现资产与RFID标签的批量快速绑定。</p>
        </div>
        <ul class="two-img">
          <li>
            <img src="../assets/资产1.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
          <li>
            <img src="../assets/资产1.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
        </ul>
        <h4>系统优势</h4>
        <div>
          <h5>RFID标签打印</h5>
          <p>RFID电子标签内涵芯片和天线，利用RFID标签打印机在可视化打印的同时将数据内容写入芯片中存取，实现资产与RFID标签的批量快速绑定。</p>
        </div>
        <ul class="two-img">
          <li>
            <img src="../assets/资产3.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
          <li>
            <img src="../assets/资产3.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
        </ul>
     </div> -->
     <!-- 暂时不要的终点 -->
    </div>
    <div class="right" v-if="newsLength!=0">
      <div class="deadline">
        <span class="title">相关客户案例</span>
        <router-link to="/newcenter/news" class="more">
          <span >更多</span><img src="../assets/arrow-right.svg" alt="">
        </router-link>
      </div>
      <ul class="relative-pro">
        <li v-for="(item, index) in cases" :key="index">
          <router-link :to="item.path">
            <p class="img-text">{{item.title}}</p>
            <div class="img-box">
              <img :src="item.img" alt="">
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import '../style/text-content-html.scss'
import getQueryString from '../plugins/getQueryString.js'
import caseimg1 from '../assets/news-1.png'
import caseimg2 from '../assets/news-2.png'
import caseimg3 from '../assets/news-3.png'

export default {
  data(){
    return{
     newsLength:0,
     relativeProducts: [],
     title: '',
     info:'',
     titleParams: [],
     path: '',
     casesImg: [caseimg1, caseimg2, caseimg3],
     cases:[]
    }
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    const languageId = sessionStorage.getItem('language')
    get('/selectFAXGAL.jsp', {faid:id}).then(function(data){
      const data0 = data.data.data
      const len = data0.length;
      self.newsLength = len
      console.log(data)
      for(var i=0; i<len; i++){
        const obj = {}
        obj.img = data0[i]['slt'] ? imageFile(data0[i]['slt']):caseimg2
        obj.path = '/newsdetail/'+data0[i]['cpid']
        obj.title = data0[i]['bt']
        self.cases.push(obj)
      }
    })
   
    get('/selectFAXQ.jsp', {faid:id}).then(function(res){
      const data0 = res.data.data;
      self.title = data0[0]['faname']
      self.info = data0[0]['xqnr']
    })
    // get('/RFID-SolutionDetails1.jsp', {langtype:languageId,Solutionid:id}).then(function(res){
    //   const data0 = res.data.data;
    //   const len = data0.length;
    //   let arr = []
    //   for(var i=0; i<len; i++) {
    //     let obj = {}
    //     obj.title = data0[i]['skt51.skf564']
    //     obj.img = imageFile(data0[i]['skt51.skf565'])
    //     obj.id = data0[i]['skt51.skf583']
    //     arr.push(obj)
    //   }
    //   console.log(arr)
    //   self.relativeProducts = arr
    // })
  }
}
</script>

<style lang="scss">
.solutions-item{
  background: #f8f8f8;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 0.75rem;
  line-height: 1.5;
  .left.mid-width{
    width: 100%;
  }
  .left{
    display: inline-block;
    width: calc(98% - 17rem);
    background: #fff;
    vertical-align: top;
    padding: 2rem 1.5rem;
    h2{
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 1rem;
    }
    .text-box{
      margin-bottom: 1rem;
      h4{
        font-size: 0.9rem;
        padding-bottom: 0.3rem;
        padding-top: 1rem;
        border-bottom: 1px dashed #dedede;
        margin-bottom: 1rem;
      }
      h5{
        font-size: 0.8rem;
        padding-bottom: 0.3rem;
      }
      img{
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .img-text{
        text-align: center;
      }
      .two-img{
        li{
          width: 49%;
          margin-right: 1%;
          display: inline-block;
        }
      }
    }
  }
  .right{
    width: 17rem;
    margin-left: 2%;
     background: #fff;
    display: inline-block;
    vertical-align: top;
    .deadline{
      padding: 1rem;
      background: #fff;
      // border-bottom: 1px solid #dedede;
      .title{
        font-size: 0.9rem;
      }
      .more{
        float: right;
        padding-top: 0.2rem;
        color:#666666;
        font-size:0.7rem;
        img{
          vertical-align: -6%;
        }
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .relative-pro{
      background: #fff;
      li{
        padding: 0 1rem 1rem 1rem;
        margin-top: 1rem;
        background: #fff;
        .img-text{
          margin-bottom: 0.5rem;
        }
        .img-box{
          width: 100%;
          overflow:hidden;
          border-radius: 4px;
          img{
            width: 100%;
            &:hover{
              transform: scale(1.1);
              transition: transform 0.4s ease-in-out
            }
          }
        }
        
        p{
          color: #666666;
        }
        &:hover{
         p{
           text-decoration: underline;
         }
        }
      }
    }
  }
}
</style>