<template>
  <div class="products-datails">
    <Header />
    <Banner />
    <MBanner />
    <NewsItem />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import NewsItem from '@/components/NewsItem.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    Footer,
    NewsItem,
    MBanner
  },
}

</script>

<style>

</style>