<template>
  <div class="our-news-list">
    <div class="container-fluid3">
      <div class="left" v-if="cases.length">
        <div class="deadline">
          <span class="title">经典案例</span>
        </div>
        <ul class="relative-pro">
          <li v-for="(item, index) in cases" :key="index">
            <router-link :to="item.path">
              <div class="img-box">
                <img :src="item.img" alt="">
              </div>
              <p class="img-text">{{item.title}}</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="right" v-if="newsList.length!=0">
        <div class="nav-i">
          <div class="deadline">
            <span class="title">行业案例</span>
          </div>
          <ul class="nav-i-header">
            <li v-for="(item, index) in industryList" :key="index" :class="{'active': industryItemActive==index}" @click="slectIndudustry(item,index)">
              {{item}}
            </li>
          </ul>
        </div>
        <div class="articles">
          <div v-for="(item, index) in newsList" :key="index" class="wrapper">
            <ul class="article-list">
              <li v-for="(item2, index2) in item" :key="index2">
                <router-link :to="item2.path">
                  <span :class="{'index': true, 'first': (index2+1)+5*index<4}">{{(index2+1)+5*index}}</span>
                  <span class="title">{{item2.title}}</span>
                  <span class="time">{{item2.time}}</span>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- <div v-if="newsList.length==0" class="nodata">
              <img src="../assets/nodata.png" alt="" >
              <p style="color:#888">没有案例哦~</p>
          </div> -->
        </div>
        <el-pagination v-show="page.pageCount>2"
          background
          layout="prev, pager, next"
          :page-count="page.pageCount"
          page-size="20"
          :current-page="tempCurrentPage"
          @current-change="changePage">
        </el-pagination>
      </div>
      <div class="default-page" v-else>
        <img src="../assets/comingsoon.png" alt="">
        <p style="font-size:20px">正在建设中，敬请期待...</p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import '../style/text-content-html.scss'
import caseimg1 from '../assets/news-1.png'
import caseimg2 from '../assets/news-2.png'
import caseimg3 from '../assets/news-3.png'
import domain from '../plugins/getDomainName.js'

export default {
  data(){
    return{
      industryFlag: false,
      relativeProducts: [],
      title: '资产管理系统解决方案',
      info:'',
      titleParams: [],
      path: '',
      casesImg: [caseimg1, caseimg2, caseimg3,caseimg1, caseimg2, caseimg3],
      cases:[],
      newsList:[],
      tempCurrentPage: 1,
      page:{
        currentPage: 0,
        pageCount:''
      },
      industryList:['全部行业'],
      industryItemActive:'0',
      labFlag:false
    }
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    const languageId = sessionStorage.getItem('language')
    if(domain.name=="lab"){
      this.labFlag = true
      self.page.pageCount = 0
    }else{
      get('/selectJDAL.jsp').then(function(data){
        const data0 = data.data.data
        const len = data0.length;
        for(var i=0; i<len; i++){
          const obj = {}
          obj.img = imageFile(data0[i]['skt28.skf489'])
          obj.path = '/newsdetail/'+data0[i]['skt28.skf303']
          obj.title = data0[i]['skt28.skf308']
          self.cases.push(obj)
        }
      })
    
      get('/index_article.jsp',{number: 0}).then(function(data){
        
        const data0 = data.data.data
        const len = data0.length;
        var result = []
        for(var i=0; i<len; i++){
          const obj = {}
          obj.time = data0[i]['skt28.skf310']
          obj.path = '/newsdetail/'+data0[i]['skt28.skf303']
          obj.title = data0[i]['skt28.skf308']
          result.push(obj)
        }
        self.sliceArray(result, 5)
      })
      get('/page_number_new.jsp',{'domainname':domain.name+'.chenksoft.com'}).then(function(data){
      self.page.pageCount = data.data.data[0]['num']
      })
      get('/select_JXHY.jsp').then(function(data){
        const data0 = data.data.data
        const len = data0.length;
        if(len){
          for(var i=0; i<len; i++){
            self.industryList.push(data0[i]['skt50.skf583'])
          }
        }
      })
    }
  },
  methods:{
    slectIndudustry(name, index){
      this.industryItemActive = index;
      this.page.currentPage = 0;
      this.tempCurrentPage = 1
      var self = this;
      if(index==0){  
        this.industryFlag = false
        this.getPageArticle()
        get('/page_number.jsp').then(function(data){
          self.page.pageCount = data.data.data[0]['number']
        })
      }else{
        this.industryFlag = true
        this.getPageIndustryArticle()
        get('/select_hyym.jsp', {hangye:name}).then(function(data){
          self.page.pageCount = data.data.data[0]['ceiling']
        })
      }
    },
    sliceArray(array, size){
      //var result = [];
      this.newsList = []
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
          var start = x * size;
          var end = start + size;
          this.newsList.push(array.slice(start, end));
      }
      //return this.newsList;
    },
    changePage(current){
      //console.log(current)
      this.tempCurrentPage = current
      this.page.currentPage = 20*(current-1)
      if(this.industryFlag){
        this.getPageIndustryArticle()
      }else{
        this.getPageArticle()
      }
      document.documentElement.scrollTop = 1300
    },
    getPageIndustryArticle(){
      var name = this.industryList[this.industryItemActive]
      var number = this.page.currentPage
      var self = this;
      get('/index_khal.jsp',{hangye:name,number:number}).then(function(data){
        const data0 = data.data.data
        const len = data0.length;
        var result = []
        for(var i=0; i<len; i++){
          const obj = {}
          obj.time = data0[i]['skt28.skf310']
          obj.path = '/newsdetail/'+data0[i]['skt28.skf303']
          obj.title = data0[i]['skt28.skf308']
          result.push(obj)
        }
        self.sliceArray(result, 5)
      })
    },
    getPageArticle(){
      var self = this;
      var number = this.page.currentPage
      this.newsList = []
      get('/index_article.jsp',{number: number}).then(function(data){
      console.log(data)
      const data0 = data.data.data
      const len = data0.length;
      var result = []
      for(var i=0; i<len; i++){
        const obj = {}
        obj.time = data0[i]['skt28.skf310']
        obj.path = '/newsdetail/'+data0[i]['skt28.skf303']
        obj.title = data0[i]['skt28.skf308']
        result.push(obj)
      }
      self.sliceArray(result, 5)
    })
    }
  }
}
</script>

<style lang="scss">
.our-news-list{
  background: #f8f8f8;
  padding-bottom: 3rem;
  padding-top: 3rem;
  font-size: 0.75rem;
  line-height: 1.5;
  .default-page{
    text-align: center;
  }
  .nav-i{
    font-size: 15px;
    color:#999;
    padding-bottom: 2rem;
    .nav-i-header{
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 1rem;
    }
    li{
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
      padding: 0.1rem 0.4rem;
      //margin-bottom: 0.5rem;
      border-radius: 4px;
      line-height: 1.7;
      &.active{
        background: #235EB6;
        color:#fff;
      }
      &:hover{
        color: #fff;
        background: #235EB6;
      }
    }
  }
  .left{
    //width: 17rem;
    width: 100%;
    display: inline-block;
    margin-bottom: 5rem;
    .deadline{
      padding: 1rem 0.5rem 2rem 0.5rem;
      //background: #fff;
      // border-bottom: 1px solid #dedede;
      .title{
        font-size: 2rem;
      }
    }
    .relative-pro{
      //background: #fff;
      display: flex;
      flex-wrap: wrap;
      li{
        margin: 0.5rem;
        display: inline-block;
        background: #fff;
        margin-bottom: 1rem;
        max-width: 18rem;
        width: 18rem;
        //flex:1;
        color: #666;
        .img-text{
          margin-bottom: 0.5rem;
          padding: 0 0.5rem;
          font-size: 16px;
        }
        .img-box{
          width: 100%;
          overflow:hidden;
          border-radius: 4px;
          img{
            width: 100%;
            &:hover{
              transform: scale(1.1);
              transition: transform 0.4s ease-in-out
            }
          }
        }
        
        p{
          color: #666666;
        }
        &:hover{
         p{
           text-decoration: underline;
         }
        }
      }
    }
  }
  .right{
    //margin-right: 4%;
    //display: inline-block;
    //width: calc(96% - 17rem);
    //background: #fff;
    vertical-align: top;
    padding: 0.5rem;
    .el-pagination{
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    .deadline{
      padding-right: 2rem;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 1rem;
      .title{
        font-size: 2rem;
        color: #222;
      }
    }
    .articles{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .wrapper{
        width: 48%;
        background: #fff;
        margin-bottom: 2rem;
      }
      .nodata{
        width: 100%;
        padding-top: 4rem;
        text-align: center;
      }
    }
    
    .article-list{
      padding: 1rem 2rem;
      li+li{
        margin-top: 1rem;
      }
      li{
        font-size: 0.8rem;
       
        line-height: 1.7;
        
        a{
          color: #222;
          &:hover{
            color: #235EB6;
          }
          .time{
            float: right;
            vertical-align: middle;
            color: #666;
            font-size: 14px;
            line-height: 2;
          }
          .index{
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            color: #999;
            border: 1px solid #dedede;
            font-size: 12px;
            border-radius: 4px;
            text-align: center;
            margin-right: 0.5rem;
            &.first{
              background: #235EB6;
              color: #fff;
              border: 1px solid #235EB6;
            }
          }
          .title{
            display: inline-block;
             vertical-align: middle;
            width: 70%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }
      // li:nth-child(5),li:nth-child(10),li:nth-child(15){
      //   border-bottom: 1px solid #dedede;
      //   padding-bottom: 1rem;
      // }
    }
  }
  .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li{
    background-color: #fff;
  }
}
</style>