<template>
  <div>
    <Header />
    <Banner />
    <MBanner />
    <GetMyDemo />
    <Footer />
  </div>
</template>
<script>
import Banner from '../components/Banner.vue'
import Footer from '../components/Footer.vue'
import GetMyDemo  from '../components/GetMyDemo.vue'
import Header from '@/components/Header.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'GetDemo',
  components: {
    Banner,
    Footer,
    GetMyDemo,
    Header,
    MBanner
  }
}
</script>