<template>
  <div class="privacy-policy">
    <Header />
    <Privacy />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Privacy from '@/components/Privacy.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'home',
  components: {
    Header,
    Footer,
    Privacy
  },
}
</script>

<style>

</style>