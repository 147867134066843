import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Platform from '../views/Platform.vue'
import Products from '../views/Products.vue'
import ProductDetails from '../views/ProductDetails.vue'
import Solutions from '../views/Solutions.vue'
import CustomizeService from '../views/CustomizeService.vue'
import GetADemo from '../views/GetADemo.vue'
import Cases from '../views/Cases.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/PrivacyPolicy.vue'
import NewsDetails from '../views/NewsDetails.vue'
import News from '../views/News.vue'
import Videos from '../views/Videos.vue'
import View00 from '../views/Solution6-it.vue'
import Hardware from '../views/Hardware.vue'
import SIView from '../views/SIView.vue'
import CertificationView from '../views/CertificationView.vue'
import Information from '../views/InformationView.vue'
import InfoDetails from '../views/InfoDetailsView.vue'

export const routes = [
  { path: '/', component: Home, name:Home},
  { path: '/platform', name: 'Platform', component: Platform},
  { path: '/products/info/:id', name: 'ProductDetails', component: ProductDetails},
  { path: '/solutions/:id', name: 'Solutions', component: Solutions},
  { path: '/service', name: 'CustomizeService', component: CustomizeService},
  { path: '/get', name: 'GetADemo', component: GetADemo},
  { path: '/cases', name: 'Cases', component: Cases},
  { path: '/company/about', name: 'About', component: About},
  { path: '/company/contact', name: 'Contact', component: Contact},
  { path: '/company/certification', name: 'Certification', component: CertificationView},
  { path: '/privacy', name: 'Privacy', component: Privacy},
  { path: '/newcenter/news', name: 'News', component: News},
  { path: '/newcenter/information', name: 'Information', component: Information},
  { path: '/newsdetail/:id', name: 'NewsDetails', component: NewsDetails},
  { path: '/infodetail/:id', name: 'InfoDetails', component: InfoDetails},
  { path: '/products/hardware', name: 'Hardware', component: Hardware},
  { path: '/products/si', name: 'SIView', component: SIView},
  { path: '/videos', name: 'Videos', component: Videos},
  { path: '/sl',  name: 'View00', component: View00},
]
const scrollBehavior = function (to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
 // 这个跟你使用 router.go() 或 router.back() 效果一致
 // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
 if (savedPosition) {
      return savedPosition
    } else {
      // 如果不是通过上述行为切换组件，就会让页面回到顶部
        return {x: 0, y: 0}
      
  }
  
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes
})



export default router
