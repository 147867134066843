<template>
  <div class="home">
    <Header />
    <Banner />
    <MBanner />
    <HardwareItem />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import HardwareItem from '@/components/HardwareItem.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    Footer,
    HardwareItem,
    MBanner
  },
  created: function() {
    
  }
}
</script>
<style lang="scss">

</style>