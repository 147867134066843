<template>
  <div :class="[cases.length ? '' : 'padding333', 'h-cases']">
    <!-- <div class="container-fluid3" v-if="cases.length">
      <div class="our-wrapper">
        <router-link to="/news" style="text-align: center; margin-top: 2rem;" class="more-mobile"><el-button type="primary" plain>查看更多</el-button></router-link>
        <ul class="our-new">
          <li v-for="(item, index) in cases" :key="index"> 
            <router-link :to="item['path']" >
              <span :class="{'num':true,'number-3': index<3}">{{index+1}}</span>
              <span class="title"> {{item['title']}}</span>
              <i v-show="index<3" class="laber">NEW</i> <span class="time">{{item['time']}}</span>
            </router-link>
          </li>
        </ul>
        
        <div class="cases-text">
          <h4 class="title">客户案例</h4>
          <p class='desc'>五千多成功客户案例，值得用户信赖</p>
          <ul class="text-item">
            <li> <i class="tick"></i>本地客户免费上门安装、调试</li>
            <li> <i class="tick"></i>异地客户远程安装调试，如需上门只收取差旅成本费</li>
            <li> <i class="tick"></i>提供电话技术支持、邮件支持及远程技术支持</li>
            <li> <i class="tick"></i>对于电话、远程解决不了的问题提供现场支持服务</li>
            <li> <i class="tick"></i>全方位地为客户提供终身可靠的服务保障</li>
          </ul>
          <p class="more"><router-link to="/news">查看更多 <i class="el-icon-arrow-right"></i></router-link></p>
        </div>
      </div>
    </div> -->
    
    <HCasesIndustry v-if="cases.length"/>
    <!-- <TitleInvSubtitle :title="title" v-else/> -->
    <swiper class="logo" :allowTouchMove="false"  :roundLengths="true"
      :loop="true" 
      :speed="30000"
      :autoplay="{delay: 0,disableOnInteraction:false}"> 
      <swiper-slide >
        <li v-for="item in items" :key="item.index">
          <img :src="item.logo" alt="">
        </li>
      </swiper-slide>
      <swiper-slide >
        <li v-for="item in items2" :key="item.index">
          <img :src="item.logo" alt="">
        </li>
      </swiper-slide>
      <swiper-slide >
        <li v-for="item in items3" :key="item.index">
          <img :src="item.logo" alt="">
        </li>
      </swiper-slide>
    </swiper>
    <!-- <ul class="logo" >
      <li v-for="item in items" :key="item.index">
        <img :src="item.logo" alt="">
      </li>
      <li v-for="item in items2" :key="item.index">
        <img :src="item.logo" alt="">
      </li>
    </ul> -->
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import HCasesIndustry from './HCasesIndustry.vue'

import logo1 from '../assets/case-阿斯.png';  
import logo2 from '../assets/case-月星.png';  import logo4 from '../assets/case-银江2.png'; import logo5 from '../assets/case-浙江.png';
import logo6 from '../assets/case-p.png'; import logo7 from '../assets/case-知味观.png'; import logo8 from '../assets/case-bank.png';
import logo9 from '../assets/case-w.png'; import logo10 from '../assets/case2-大搜车.png';
import logo11 from '../assets/case3-工业.png'; import logo12 from '../assets/case4-华大.png';
import logo13 from '../assets/case5-华东理工.png'; import logo14 from '../assets/case6-华东医药.png';
import logo15 from '../assets/case-boe.png'; import logo16 from '../assets/case8-京.png';
import logo18 from '../assets/case10-中石化.png';import logo17 from '../assets/case-电网.png';
import logo19 from '../assets/case12-中国铁建.png'; import logo20 from '../assets/case13-中国石油.png';
import logo21 from '../assets/case15-中国地质大学.png'; import logo22 from '../assets/case16-中国东方航空.png';
import logo23 from '../assets/case17-中储粮.png'; import logo24 from '../assets/case18-上海.png';
import logo25 from '../assets/case19-二所.png'; 
import logo26 from '../assets/c-万景.png'; 
import logo27 from '../assets/c-能源.png'; 

import logo1en from '../assets/case-feisen.png';  
import logo2en from '../assets/case-sankyo.png';  
import logo5en from '../assets/case-jinbin.png';  
import logo6en from '../assets/case-meilejia.png';  
import logo7en from '../assets/case-ppg.png';  
import logo8en from '../assets/case-nidec.png';  
import logo9en from '../assets/case-huvitz.png';  
import logo13en from '../assets/case-boke.png';  
import logo14en from '../assets/case-thermo.png';  
import logo15en from '../assets/case-green-valley.png';  
import logo16en from '../assets/case-career.png';  
import logo17en from '../assets/case-tyk.png';  
import logo24en from '../assets/case-gke.png';  
import logo25en from '../assets/case-yena.png';  
import logo26en from '../assets/case-ferrotec.png';  
import logo27en from '../assets/case-sikeluo.png';  
import logo28en from '../assets/case-weber.png';  
import logo29en from '../assets/case-lekaibao.png';  

import logo31en from '../assets/case-xinyue.png';  
import logo32en from '../assets/case-warner.png';  
import logo33en from '../assets/case-hengsi.png';


import { get,imageFile } from '../plugins/request.js'
import domain from '../plugins/getDomainName.js'

export default {
data(){
    return {
      cases:[],
      title: {
        title: '客户案例',
        subtitle: '五千多成功客户案例，值得用户信赖'
      },
      items2: [
        {logo: logo26},{ logo: logo14en },{ logo: logo5en },{ logo: logo26en },{logo: logo27},{ logo: logo28en },
        { logo: logo27en },{ logo: logo29en },{ logo: logo24en },{ logo: logo25en },{ logo: logo7en },{ logo: logo8en },
        { logo: logo31en },{ logo: logo32en },{ logo: logo9 }, { logo: logo16en }
      ],
      items3: [
        { logo: logo33en },{ logo: logo2en },{ logo: logo1en },{ logo: logo13en },{ logo: logo6en },{ logo: logo9en },
        { logo: logo15en },{ logo: logo17en },{ logo: logo11 },{ logo: logo14 },{ logo: logo16 },{ logo: logo18 },
        { logo: logo2 },{ logo: logo12 },{ logo: logo13 }, { logo: logo6 }
       ],
      languageId: '',
      items: [
        { logo: logo17 },{ logo: logo1 },{ logo: logo4 },{ logo: logo5 },{ logo: logo19 },{ logo: logo20 },
        { logo: logo22 },{ logo: logo21 },{ logo: logo23 },{ logo: logo24 },{ logo: logo25 },
        { logo: logo7 },{ logo: logo8 },{ logo: logo10 },{ logo: logo9 },{ logo: logo15 }
      ]
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    HCasesIndustry
  },
  created() {
    const self = this;
    if(domain.name=="lab"){
      self.cases.length = 0
    }else{
      get('/index_article.jsp',{'number': 0}).then(function(data){
        const data0 = data.data.data
        const len = data0.length;
        if(len){
          for(var i=0; i<8; i++){
            const obj = {}
            obj.path = '/newsdetail/'+data0[i]['skt28.skf303']
            obj.time = data0[i]['skt28.skf310']
            obj.title = data0[i]['skt28.skf308']
            self.cases.push(obj)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

.h-cases{
  //#F8F9FD
  background: url(../assets/case-bg.png) center bottom no-repeat;
  padding: 3rem 0;
  .more-mobile{
    display: none;
  }
  .our-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cases-text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 2rem;
      .text-item{
        font-size: 0.8rem;
        color:#666666;
        margin-top: 1rem;
        li{
          padding: 0.3rem 0;
        }
        .tick{
          background: url('../assets/tick.png') no-repeat;
          width: 14px;
          height: 13px;
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
      .title{
        font-size: 2rem;
        color: #222;
        font-weight: normal;
      }
      .desc{
        font-size: 1.2rem;
        color:#666;
        font-weight: 200;
        width: 27.5rem;
        line-height: 1.7;
        margin-top: 1rem;
      }
      .more{
        font-size: 0.9rem;
        margin-top: 2rem;
        a{
          color: #235EB6;
          cursor: pointer;
        }
      }
    }
    .our-new {
      background-color:#fff;
      width: 40rem;
      padding: 1rem 2rem;
      border-radius: 4px;
      li{
        display: block;
        font-size: 0.8rem;
        color:#222;
        padding: 0.6rem 0;
        a{
          width: 100%;
          display: inline-block;
          &:hover{
           .title{
              color: #235EB6;
           }
          }
        }
        .laber{
          color:#A123DC;
          font-weight: bold;
          font-size: 0.75rem;
          margin-left: 0.5rem;
        }
        .time{
          color: #666;
          float: right;
        }
        .num{
          color: #999;
          display: inline-block;
          width: 1.2rem;
          &.number-3{
             color:#A123DC
          }
        }
      }
    }
  }
  .logo{
    margin-top: 4rem;
    li{
      width: 10.83%;
      margin: 0.83%;
      padding: 0.2rem 0.83%;
      display: inline-block;
      background: #fff;
      border-radius: 2px;
      img{
        width: 100%;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
  }
  .swiper-container .swiper-wrapper{
    -webkit-transition-timing-function: linear; /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  &.padding333{
    .logo{
      margin-top:0rem
    }
  }
}

</style>