<template>
  <div class="video-list container-fluid3">
    <div class="container">
      <div class="left" >
        <div class='my-video'>
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer"
            :palysinline="true"
            :options="playerOptions"></video-player>
        </div>
      </div>
      <div class="right">
        <div class="deadline">
          <span class="title">视频列表 <span style="font-size: 16px;color:#666;" class="mobile-span">({{activeVideo.index+1}}/{{videoPoster.length}})</span> </span>
        </div>
        <ul class="list ck-scroll-bar">
          <li v-for="(item, index) in videoPoster" :key="index" @click="changeVideo(index, item)" :class="{'active': index==activeVideo.index}">
            <img :src="item.poster" alt="" class="cover">
            <div class="video-list-detail">
              <img src="../assets/playing.gif" alt="" class="playing" v-show="index==activeVideo.index">
              <p>{{index+1}}.{{item.title}}</p> 
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-maskin" v-show="playingflag">
      <div :class="['my-video2', {'video-fixed':playingflag}]" >
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer"
          :palysinline="true"
          :options="playerOptions"></video-player>
      </div>
      <div class="maskin" @click="closemask"></div>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import '../style/text-content-html.scss'
import getQueryString from '../plugins/getQueryString.js'
import caseimg1 from '../assets/news-1.png'
import caseimg2 from '../assets/news-2.png'
import caseimg3 from '../assets/news-3.png'

import cover1 from '../assets/cover1.png'
import cover2 from '../assets/cover2.png'

export default {
  data(){
    return{
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      myPlayer:'',
      myPlayerm:'',
      playingflag:false,
      activeVideo: {
        title: '',
        desc: '',
        id: '',
        poster: '',
        index:0
      },
      videoPoster:[]
    }
  },
  created(){
    const self = this;
     get('/get_video.jsp').then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      for(var i=0; i<len; i++){
        const obj = {}
        obj.title = data0[i]['skt25.skf263']
        obj.img = imageFile(data0[i]['skt25.skf270'])
        obj.id = data0[i]['skt25.skf261']
       // obj.desc = data0[i]['video_content']
        obj.desc = data0[i]['skt25.skf269']
        obj.poster = imageFile(data0[i]['skt25.skf270'])
        obj.url =  data0[i]['skt25.skf264']
        obj.index =  i
        self.videoPoster.push(obj)
      }
      self.activeVideo = self.videoPoster[0]
      self.playerOptions.sources[0].src = self.videoPoster[0]['url']
      self.playerOptions.poster = self.videoPoster[0]['poster']
    })
    
  },
  methods: {
    changeVideo(index, item){
      //console.log(item)
      this.playingflag = true;
      this.activeVideo = item
      const self = this;
      self.playerOptions.sources[0].src = self.activeVideo['url']
      self.playerOptions.poster = self.activeVideo['poster']
    },
    closemask(){
      this.playingflag = false;
    }
  }
}
</script>

<style lang="scss">
.video-list{
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 0.75rem;
  line-height: 1.5;
  .maskin{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1001;
  }
  .my-video2{
    width: 100%;
  }
  .video-fixed{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1002;
  }
  .mobile-maskin{
    display: none;
    width: 100%;
  }
  .container{
    display: flex;
  }
  .right{
    width: 14rem;
    display: inline-block;
    vertical-align: top;
    background: #f4f4f4;
    margin-left: 2rem;
    height: 25rem;
    .deadline{
      padding: 1rem;
      .title{
        font-size: 1.2rem;
        color: #222;
      }
    }
    .list{
      overflow-y: scroll;
      height: 21.2rem;
      li{
        position: relative;
        margin-bottom: 1rem;
        cursor: pointer;
        padding: 1rem 1rem 0.5rem 1rem;
        margin: 0.5rem;
        color: #222;
        &.active{
          background: #fff;
          color: #235EB6;
          border-radius: 4px;
        }
        .cover{
          width: 100%;
        }
        
        .video-list-detail{
          //position: absolute;
          //  background-image: url(../assets/video_rnews.png);
          // background-repeat:no-repeat;
          // background-size:100% 100%;
          //background: rgba($color: #000000, $alpha: 0.3);
          //width: 100%;
          //bottom: 0;
          //left: 0;
          //padding: 10px;
          p{
           margin-top: 0.2rem;
            //font-weight: 600;
            //text-shadow: 1px 1px 2px #666;
            font-size: 14px;
            display: inline-block;
          }
          .playing{
            display: inline-block;
            width: 16px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        
      }
    }
  }
  .left{
    display: inline-block;
    width: 40rem;
    background: #fff;
    vertical-align: top;
    .my-video{
      width: 100%;
    }
    
    .el-pagination{
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    .deadline{
      padding: 1rem 2rem;
      background: #fff;
      .title{
        font-size: 1.8rem;
      }
    }
    .article-list{
      padding: 1rem 2rem;
      min-height: 50rem;
      li{
        font-size: 0.8rem;
        margin-bottom: 1rem;
        line-height: 1.7;
        a{
          color: #222;
          &:hover{
            color: #235EB6;
          }
          .time{
            float: right;
          }
        }
      }
      li:nth-child(5),li:nth-child(10),li:nth-child(15){
        border-bottom: 1px solid #dedede;
        padding-bottom: 1rem;
      }
    }
  }
}
.ck-scroll-bar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px !important;
    height: 6px !important;
}
/*正常时候的主干部分*/

.ck-scroll-bar::-webkit-scrollbar-track {
  /* background: rgba(0, 0, 0, 0.1); 
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
   background-color: transparent;
}
/*正常情况下滑块的样式*/

.ck-scroll-bar::-webkit-scrollbar-thumb {
 /*  background: rgba(0, 0, 0, 0.5);
 /* background-color: darkgrey;
 /*  outline: 1px solid slategrey; */
 background-color: rgba(0, 0, 0, 0);
 border-radius: 10px;
} 
.ck-scroll-bar:hover::-webkit-scrollbar-thumb {
	background-color: rgba(144,147,153,.3);

}
/*鼠标悬浮在滑块上时滑块的样式*/

.ck-scroll-bar::-webkit-scrollbar-thumb:hover {
   background-color: rgba(144,147,153,.5);
}
.ck-scroll-bar::-webkit-scrollbar{
	-webkit-appearance: none;
    width: 6px !important;
    height: 6px !important;
}

</style>