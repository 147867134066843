<template>
  <div class="our-information container-fluid3">
    <TitleInvSubtitle :title="title" v-if="newsList.length!=0"/>
    <div class="default-page" v-else>
      <img src="../assets/comingsoon.png" alt="">
      <p style="font-size:20px">正在建设中，敬请期待...</p>
    </div>
    <div>
      <ul class="info-list">
        <li v-for="(item, index) in newsList" :key="index">
          <router-link :to="'/infodetail/'+item.id">
            <div class="img-box">
              <img :src="item.img" alt="">
            </div>
            <div class="text-box">
              <h2>{{item.title}}</h2>
              <p>{{item.desc}}</p>
              <div class="button">
                <span><i class="el-icon-timer"></i>{{item.time}}</span>
                <!--<router-link to='/'>查看详情</router-link>-->
                <router-link :to='item.path'>查看详情</router-link>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <el-pagination v-if="page.pageCount>2"
        background
        layout="prev, pager, next"
        :page-count="page.pageCount"
        page-size="20"
        @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import '../style/text-content-html.scss'
import getQueryString from '../plugins/getQueryString.js'
import caseimg1 from '../assets/news-1.png'
import caseimg2 from '../assets/news-2.png'
import caseimg3 from '../assets/news-3.png'
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'

export default {
  data(){
    return{
      path:'',
      title:{title:'行业资讯'},
      page:{
        currentPage: 0,
        pageCount: 0
      },
      newsList:[]
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this
    get('./select_hylb.jsp').then(function(data){
     const data0 = data.data.data
     const len = data0.length
     for(var i =0; i<len; i++){
       var obj = {}
       obj.time = data0[i]['skt54.skf626']
       obj.img = imageFile(data0[i]['skt54.skf631'])
       obj.title = data0[i]['skt54.skf624']
       obj.desc = data0[i]['skt54.skf632']
       obj.id = data0[i]['skt54.skf619']
       obj.path = '/infodetail/'+data0[i]['skt54.skf619']
       self.newsList.push(obj)
     }
   })
   get('./select_hyzym.jsp').then(function(data){
     self.pageCount = data.data.data[0]['ceiling']
   })
  },
  methods:{
   changePage(current){
      //console.log(current)
      this.page.currentPage = 20*(current-1)
      //document.documentElement.scrollTop = 1300
      this.newsList = []
      get('./select_hylb.jsp',{number: this.page.currentPage}).then(function(data){
        const data0 = data.data.data
        const len = data0.length
        for(var i =0; i<len; i++){
          var obj = {}
          obj.time = data0[i]['skt54.skf626']
          obj.img = imageFile(data0[i]['skt54.skf631'])
          obj.title = data0[i]['skt54.skf624']
          obj.desc = data0[i]['skt54.skf632']
          obj.id = data0[i]['skt54.skf619']
          self.newsList.push(obj)
        }
      })
    },
  }
}
</script>

<style lang="scss">
.our-information{
  background: #f8f8f8;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .default-page{
    text-align: center;
  }
  .el-pagination{
    text-align: center;
    margin-top: 4rem;
  }
  .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li{
    background-color: #fff;
  }
  .info-list{
    display: flex;
    //justify-content: space-around;
    flex-wrap: wrap;
    li{
      width: 23%;
      background: #fff;
      margin: 1%;
      .img-box{
        border-radius: 4px;
        overflow: hidden;
        img{
          width: 100%;
          &:hover{
            transform: scale(1.1);
            transition: transform 0.4s ease-in-out
          }
        }
      }
      .text-box{
        padding: 1rem;
        h2{
          font-size: 20px;
          height: 54px;
          font-weight: normal;
          color:#444;
          margin-bottom: 1rem;
          overflow: hidden;
        }
        p{
          font-size: 14px;
          color:#666;
          line-height: 26px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .button{
          font-size: 14px;
          margin-top: 1rem;
          color:#999;
          height: 30px;
          span{
            line-height: 30px;
            i{
              margin-right: 4px;
            }
          }
          a{
            font-size: 13px;
            color: #235EB6;
            border: 1px solid #235EB6;
            border-radius: 4px;
            padding: 6px 10px;
            float: right;
            &:hover{
              color: #fff;
              background: #235EB6;
            }
          }
        }
      }
    }
  }
}
</style>