<template>
  <div class="text-content-h5">
    <div class="text-item">
      <h4>GMP管理问题</h4>
      <ul class="text-ul">
        <li>1、GMP工作量日益增大，历史文档的积累，信息量成倍增加</li>
        <li>2、GMP数据负荷日益增大，手工管理方式企业增长速度慢阻碍企业发展</li>
        <li>3、GMP对生成过程中工序管理要求，规范操作流程混乱，达不到预想效果</li>
      </ul>
    </div>
    <div class="text-item">
      <h4>产品信息</h4>
      <h5>包括文件管理(DMS)、培训管理（TMS）、质量管理（QMS）</h5>
      <ul class="text-item-grey text-ul text-indent0" style="margin-top: 1rem;">
        <li>
          <b>● DMS：</b>包括文档在线起草文件及审批、修订申请、培训管理、模块管理、文件管理等功能，使制药企业文件全生命周期管理符合GMP规范要求，提高文件的质量和效率
        </li> 
        <li><b>● TMS：</b>包括培训计划、培训资料、试题管理、培训考核、在线答题等功能，对制药企业培训工作综合化管理系统，培训工作集中管控，提高培训效果和人员技能</li> 
        <li><b>● QMS：</b>包括偏差管理、变更管理、纠正预防措施管理（CAPA）等功能，业务流程根据GMP规范进行设计，确保合规性，降低质量风险提高工作效率节约时间</li> 
      </ul>   
    </div>
    <div class="text-item">
      <h4>产品特点</h4>
      <div>
        <h5>1、文件管理流程化</h5>
        <p>可设置多格式的内容模板，减少审核时间，提高文件审批效率，文件进行严格管理的培训管理，并管控文件的生效，可对文件进行版本管理，同步最新版本，回滚版本</p>
        <div class="center-img"><img src="https://hz.chenksoft.com:443//upload/image/24983825216064504.png" alt="搜索结果" style="max-width: 100%;"></div>
      </div>
      <div>
        <h5>2、培训考核规范化</h5>
        <p>可设置不同周期，不同方式的培训计划，对培训时间、地点、讲师、人员进设置培训信息，确保培训计划按时实施</p>
        <div class="center-img"><img src="https://hz.chenksoft.com:443//upload/image/06242098637655058.png" alt="搜索结果" style="max-width: 100%;"></div>
      </div>
      <div>
        <h5>3、在线答题自动生成分数</h5>
        <p>统设置题库管理，根据培训生成对应的试题，支持在线考试自动阅卷生成分数，并获取相应的培训合格率和人员技能，有效提高培训考核合格率和准确性</p>
        <div class="center-img"><img src="https://hz.chenksoft.com:443//upload/image/31237944946037843.png" alt="搜索结果" style="max-width: 100%;">
        <img  src="https://hz.chenksoft.com:443//upload/image/4944228920801115.png" alt="搜索结果" style="max-width: 100%;"></div>
      </div>
      <div style="margin-bottom:1rem">
        <h5>4、质量严格规范化</h5>
        <p>根据不同的产品类型设置相应的产品自定义表单，查看各个流程的进展情况，快速获取最新资料和各项工作进度，合理利用企业资源，系统实现对质量管理过程中的数据进行实时分析和统计，分析质量问题及分析不足之处快速处理问题</p>
      </div>
      <div>
        <h5>5、审批流程多样化</h5>
        <p>自定义各个审批流程</p>
        <div class="center-img"><img src="https://hz.chenksoft.com:443//upload/image/9274413934785295.png" alt="审批" style="max-width: 100%;"></div>
      </div>
      <div>
        <h5>6、提高用户服务水平</h5>
        <p>持在线编辑、在线预览、下载打印等功能，为客户提供全面信息增值服务</p>
        <div class="center-img"><img src="https://hz.chenksoft.com:443//upload/image/21160566128511826.png" alt="打印" style="max-width: 100%;"></div>
      </div>
      <div>
        <h5>7、数据统计和报表</h5>
        <p>供各种统计报表，方便对GMP文件档案管理工作的监控、评估和管理，GMP文件档案管理软件可以追溯物资各个环节动态管理</p>
      </div>
    </div>
    <div class="text-item">
      <h4>产品优势</h4>
      <ul>
        <li>1、制药企业明确质量标准，细化管理资质，持续改进质量管理水平</li>
        <li>2、质量信息的规范性、有效性和完整性，避免传统模式下的弊端</li>
        <li>3、GMP数据电子化管理方式，适应企业快速增长，助力企业发展</li>
        <li>4、可确保文件不丢失。避免了因为众多的质量文件可能是散落在不同的电脑和硬盘中，和缺乏统一的存放和管理的原因，而造成资料的丢失</li>
      </ul>
    </div>
  </div>
</template>

<script>
import '../style/text-content-html.scss'

export default {
  
}
</script>

<style lang="scss">

</style>