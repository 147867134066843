<template>
  <div class="privacy container-fluid2">
    <div class="box" v-html="content"></div>
    <div class="box" style="display: none">
      <h1>晨科软件隐私政策</h1>
      <h2>隐私政策</h2>
      <p>最后更新:2021-04-14</p>
      <p>陈科软件运营<a href="https://rfid.chenksoft.com">https://rfid.chenksoft.com/</a> . 本页告知您我们关于收集、使用和披露从本网站用户获得的个人信息的政策。</p>
      <p>我们仅将您的个人信息用于提供和改进本网站。当您使用本网站时，即表示您同意按照本政策收集和使用信息。</p>
      <h2>信息收集和使用</h2>
      <p>在使用我们的网站时，我们可能会要求您向我们提供某些可用于联系或识别您身份的个人身份信息。个人身份信息可能包括，但不限于您的。</p>
      <h2>通信</h2>
      <p>我们可能会使用您的个人信息与您联络，提供通讯、市场推广或宣传材料及其他信息。</p>
      <p>通信部分是为那些可能通过电子邮件(电子邮件通讯)或其他方法联系用户的企业而设的。对于完整披露部分，创建您自己的隐私政策。</p>
      <h2>Cookies</h2>
      <p>cookie是包含少量数据的文件，可能包含匿名唯一标识符。</p>
      <p>Cookies从网站发送到你的浏览器，并存储在你的电脑硬盘上。</p>
      <p>246/5000 
像许多网站一样，我们使用“cookies”来收集信息。您可以指示浏览器拒绝所有cookie或指示何时发送cookie。但是，如果您不接受cookies，您可能无法使用我们网站的部分内容。</p>
      <h2>安全</h2>
      <p>您的个人信息的安全对我们很重要，但请记住，任何通过互联网传输的方法或电子存储的方法都不是100%安全的。虽然我们努力使用商业上可接受的方法来保护您的个人信息，但我们不能保证其绝对安全。</p>
      <h2>对本隐私政策的更改</h2>
      <p>本隐私政策自2021年4月14日起生效，并将继续有效，除非其条款在未来发生任何变更，该变更将在发布到本页后立即生效。</p>
      <p>我们保留随时更新或更改我们的隐私政策的权利，您应定期查看本隐私政策。在我们在本页上发布对隐私政策的任何修改后，您继续使用本服务将构成您对修改内容的承认，以及您同意遵守并受修改后的隐私政策的约束。</p>
      <p>如果我们对本隐私政策作出任何重大更改，我们将通过您提供给我们的电子邮件地址或在我们的网站上显著公告通知您。</p>
      <h2>联系我们</h2>
      <p>如果您对本隐私政策有任何疑问，请联系我们 <a href="mailto:info@chenksoft.com">info@chenksoft.com</a></p>
    </div>
    <div class="box" style="display: none">
      <h1>ChenkSoft Technology Privacy Policy </h1>
      <h2>Privacy Policy</h2>
      <p>Last updated: 14/April/2021</p>
      <p>Our Company ChenkSoft Technology operates <a href="https://rfid.chenksoft.com">https://rfid.chenksoft.com/</a> . This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>
      <p>We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>
      <h2>Information Collection and Use</h2>
      <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your .</p>
      <h2>Communications</h2>
      <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information.</p>
      <p>The Communications section is for businesses that may contact users via email (email newsletters) or other methods. For the full disclosure section, create your own Privacy Policy </p>
      <h2>Cookies</h2>
      <p>Cookies are files with small amount of data, which may include an anonymous unique identifier.</p>
      <p>Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
      <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
      <h2>Security</h2>
      <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
      <h2>Changes To This Privacy Policy</h2>
      <p>This Privacy Policy is effective as of 14/April/2021 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
      <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your Consent to abide and be bound by the modified Privacy Policy.</p>
      <p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us: <a href="mailto:info@chenksoft.com">info@chenksoft.com</a></p>
    </div>
     <div class="box" style="display: none">
      <h1>Politiques de confidentialité de la technologie ChenkSoft </h1>
      <h2>Politique de confidentialité</h2>
      <p>Dernière mise à jour: 14 / avril / 2021</p>
      <p>Notre société ChenkSoft Technology exploite<a href="https://rfid.chenksoft.com">https://rfid.chenksoft.com/</a> .  Cette page vous informe de nos politiques concernant la collecte, l'utilisation et la divulgation des renseignements personnels que nous recevons des utilisateurs du site.</p>
      <p>Nous utilisons vos informations personnelles uniquement pour fournir et améliorer le site. En utilisant le site, vous acceptez la collecte et l'utilisation des informations conformément à cette politique.</p>
      <h2>Collecte et utilisation des informations</h2>
      <p>Lors de l'utilisation de notre site, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables,qui peut être utilisé pour vous contacter ou vous identifier. Les informations personnellement identifiables peuvent inclure, mais sans s'y limiter, votre.</p>
      <h2>Les communications</h2>
      <p>Nous pouvons utiliser vos informations personnelles pour vous contacter avec des newsletters, du marketing ou des promotions matériaux et autres informations.</p>
      <p>La section Communications est destinée aux entreprises qui peuvent contacter les utilisateurs par e-mail (e-mail newsletters) ou d’autres méthodes. Pour la section de divulgation complète, créez votre propre politique de confidentialité.</p>
      <h2>Cookies</h2>
      <p>Les cookies sont des fichiers contenant une petite quantité de données, qui peuvent inclure un identifiant unique anonyme.</p>
      <p>Les cookies sont envoyés à votre navigateur à partir d'un site Web et stockés sur le disque dur de votre ordinateur.</p>
      <p>Comme de nombreux sites, nous utilisons des «cookies» pour collecter des informations. Vous pouvez demander à votre navigateur de refuser tout cookies ou pour indiquer quand un cookie est envoyé. Cependant, si vous n'acceptez pas les cookies, vous pouvez ne pas pouvoir utiliser certaines parties de notre site.</p>
      <h2>Sécurité</h2>
      <p>La sécurité de vos informations personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de la transmission sur Internet, ou méthode de stockage électronique, est sécurisée à 100%. Alors que nous nous efforçons de utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons garantir leur sécurité absolue.</p>
      <h2>Modifications de cette politique de confidentialité</h2>
      <p>Cette politique de confidentialité est en vigueur à compter du 14 / avril / 2021 et restera en vigueur, sauf en ce qui concerne tout changement de ses dispositions à l'avenir, qui sera en vigueur immédiatement après sa publication sur cette page.</p>
      <p>Nous nous réservons le droit de mettre à jour ou de modifier notre politique de confidentialité à tout moment et vous devriez vérifier ceci. Politique de confidentialité périodiquement. Votre utilisation continue du Service après la publication de toute modification du La politique de confidentialité sur cette page constituera votre reconnaissance des modifications et votre, Consentir à respecter et à être lié par la politique de confidentialité modifiée.</p>
      <p>Si nous apportons des modifications importantes à cette politique de confidentialité, nous vous en informerons soit par e-mail. l'adresse que vous nous avez fournie, ou en plaçant un avis bien en vue sur notre site Web.</p>
      <h2>Nous contacter</h2>
      <p>Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter: <a href="mailto:info@chenksoft.com">info@chenksoft.com</a></p>
    </div>
  </div>
</template>

<script>
import {get} from '../plugins/request'

export default {
  components: {
    
  },
  data() {
    return {
      content:''
    }
  },
  created(){
    const language = sessionStorage.getItem('language')
    const self = this;
    get('/RFID_PrivatePolicy.jsp', {langtype:language,infoType:1}).then(function(data){
      self.content = data.data.data[0].info
    })
  }
}
</script>
<style lang="scss">
.privacy{
  background: #F7FAFC;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .box{
    margin: 0 auto;
    width: 46rem;
    background: #fff;
    padding: 3rem;
    font-size: 0.8rem;
    border-radius:0.2rem;
    color: #444444;
    a{
      color:#235EB6;
      text-decoration: underline;
    }
    h1{
      font-size: 1.6rem;
      font-weight: 600;
      padding-bottom:0.5rem;
      border-bottom: 3px double rgba(0,0,0,.1)
    }
    h2{
      font-size: 1.4rem;
      margin: 2.5rem 0 1rem;
      padding-bottom: .5rem;
      border-bottom: 1px double rgba(0,0,0,.1);
    }
    p{
      line-height: 1.6;
    }
    p+p{
      margin-top: 1rem;
    }
  }
}
</style>