<template>
<div class="platform-4 container-fluid3">
  <TitleInvSubtitle :title="title"/>
   <ul class="items">
    <li v-for="item in items" :key="item.index">
      <div class="img-icon">
        <img :src="item.image" alt="" >
      </div>
      <div class="text">
        <span class="title">{{ item.title }}</span>
        <p>{{ item.description }}</p> 
      </div>
    </li>
  </ul>
</div>
</template>
<script>
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { get2, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import icon1 from '../assets/tools1.png'
import icon2 from '../assets/tools2.png'
import icon3 from '../assets/tools3.png'
import icon4 from '../assets/tools4.png'

export default {
  name: 'PFTools',
  components: {
    TitleInvSubtitle 
  },
  data() {
    return {
      title: {
        title: '',
        subtitle: ''
      },
      items: [
        { image: icon1, title: '', description: ''},
        { image: icon2, title: '', description: ''},
        { image: icon3, title: '', description: ''},
        { image: icon4, title: '', description: ''},
      ],
     
    }
  },
  methods: {
    getValue(params) {
      const self = this;
      get2('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['p4Title1']
        self.title.subtitle = title['p4Title2']
        for(var i=0;i<4; i++){
          self.items[i].title = title[`p4Title${i+3}`]
          self.items[i].description = title[`p4D${i+3}`]
        }
      })
    }
  },
  created: function(){
    const languageId = sessionStorage.getItem('language')
    this.getValue({lang: 0, value: 'p4Title1;p4Title2;p4Title3;p4D3;p4Title4;p4D4;p4Title5;p4D5;p4Title6;p4D6;'})
  },
}
</script>
<style lang="scss">
.platform-4 {
  padding-bottom: 3rem;
  padding-top: 1rem;
  background: #f8f8f8;
  
  .items {
    padding: 2rem 2% 1rem 2%;
    background: #fff;
    li {
      display: inline-block;
      width: 48%;
      margin: 1%;
      vertical-align: top;
      .img-icon{
        width: 10%;
        display: inline-block;
        padding: 1%;
        vertical-align: top;
        img{
          width: 100%;
        }
      }
      .text{
        width: 90%;
        display: inline-block;
        padding: 0 1rem 1rem 1rem;
        p {
          margin-top: 0.5rem;
          width: 90%;
          opacity: 0.8;
          font-size: 0.7rem;
          line-height: 1.6;
        }
      }
    }
  }
}
</style>