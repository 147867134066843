<template>
  <div class="back-to-top" :class="{show:isActive}">
    <div class="circle" @click="toTop(step)">
      <img src="../assets/backtop.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isActive: false
    }
  },
  props: {
    step:{   //控制速度
      type: Number,
      default: 40  
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    // 移除事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll (e) {
      if (document.documentElement.scrollTop > 600) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    toTop(step){
      console.log(step)
      //参数step表示时间间隔的幅度大小，以此来控制速度
      //当回到页面顶部的时候需要将定时器清除
      const s =  Math.pow(1000 / document.documentElement.scrollTop, 2)+40
      //console.log(s)
      document.documentElement.scrollTop-=step*s;
      if (document.documentElement.scrollTop>0) {
          var time=setTimeout(()=>this.toTop(step),15);
      }else {
          clearTimeout(time);
      }
    },
  }
}
</script>

<style lang="scss">

.back-to-top{
  &.show{
      display: block;
  }
  display: none;
  position:fixed;
  bottom: 8.5rem;
  right: 1.2rem;
  z-index: 999;
  .circle{
    cursor: pointer;
    width: 3em;
    height: 3rem;
    background: rgba($color: #000000, $alpha: 0.8);
    border-radius: 50%;
    text-align: center;
    img{
        margin-top:1.1rem ;
    }
  }
}
</style>