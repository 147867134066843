<template>
<div style="height:3.2rem" id="nav-box" class="nav-box-desktop">
  <div :class="{'header2 container-fluid2':true,'fix-nav':navBarFixed}" id="navheader">
    <ul class="nav" v-if="navMenu">
      <li v-for="(item,index) in navMenu" :key="index">
        <router-link :to="'/products/'+item.id" active-class="active">
          <img :src="item.img2" alt="">
          <span>{{item.title}}</span>
        </router-link>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import {getProMenu} from '../plugins/navMenu.js'
import icon1 from '../assets/nav-small-0.png'
import icon2 from '../assets/nav-small-1.png'
import icon3 from '../assets/nav-small-2.png'
import icon4 from '../assets/nav-small-3.png'
import icon5 from '../assets/nav-small-4.png'
import icon6 from '../assets/nav-small-5.png'

export default {
  data() {
    return {
      navBarFixed: false,
      scroolI: 0,
      fixedHigh:0,
      navMenu:'',
      languageId: ''
    };
  },
    mounted() {
      // 事件监听滚动条
      window.addEventListener("scroll", this.watchScroll);
    },

    unmounted() {
      // 移除事件监听
      window.removeEventListener("scroll", this.watchScroll);
    },

    methods: {
      watchScroll() {
        // 滚动的距离
        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;

        // 容器的高度
        var offsetTop = document.querySelector("#navheader").offsetTop
        const fixedHigh = document.querySelector("#nav-box").offsetTop

        //判断window滚动条上下滚动
        const scrollDir = scrollTop - this.scroolI
        this.scroolI = scrollTop;
        //console.log(scrollTop,offsetTop,scrollDir,fixedHigh)
        //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
        if(scrollDir>0){
          this.navBarFixed = scrollTop > offsetTop ? true : false;
        }else{
          this.navBarFixed = scrollTop > fixedHigh ? true : false;
        }
      },
      async navMenu2() {
        const languageId = this.languageId
        let menu = await getProMenu(languageId)
        menu[0].img2 = icon1;
        menu[1].img2 = icon2;
        menu[2].img2 = icon3;
        menu[3].img2 = icon4;
        menu[4].img2 = icon5;
        menu[5].img2 = icon6;
        this.navMenu = menu;
      }
    },
    created() {
      this.languageId = sessionStorage.getItem('language')
      this.navMenu2()
    },
  
}
</script>

<style lang="scss">
.fix-nav{
  position: fixed;
    top: 0;
    z-index: 999;
}
  .header2{
    width: 100%;
    height: 3.2rem;
    background: #262830;
    color: #c6c6c6;
    .nav{
      height: 100%;
      li{
        width: 16.66%;
        height: 100%;
        display: inline-block;
        font-size: 0.8rem;
        text-align: center;
        line-height: 4.2;
        overflow: hidden;
        a{
          cursor: pointer;
          color: inherit;
          display: inline-block;
          width: 100%;
          height: 100%;
          &:hover,
          &.active{
            background: #235EB6;
            color: #fff;
          }
        }
        img{
          width: 0.8rem;
          margin-right: 0.5rem;
          vertical-align: -1%;
        }
        
      }
    }
    
  }
</style>