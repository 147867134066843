<template>
  <div class="side-nav-bar">
<!--<el-tooltip class="item" effect="dark" content="23058435433" placement="left">
    <a href="https://api.whatsapp.com/send?phone=23058435433" > <img src="../assets/whatsapp.svg" alt=""></a>
    </el-tooltip> -->
  </div>
</template>

<style lang="scss">
.side-nav-bar{
  position: fixed;
  right: 1.2rem;
  bottom: 4.5rem;
  z-index:1000;
  a{
    cursor: pointer;
    width: 3em;
    height: 3rem;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    text-align: center;
    display: inline-block;
  }
  img{
    width: 2rem;
    margin-top: 0.5rem;
  }
}
</style>