import axios from "axios"
import img1 from '../assets/未获取图片.svg'
import domain from './getDomainName.js'

//创建一个axios对象
  var instance = axios.create({
  baseURL: 'https://hz.chenksoft.com/ckapi/api/3/v2/',
  //timeout: 5000
  timeout: 50 * 1000
})

const instance2 = axios.create({
  baseURL: 'https://hz.chenksoft.com/ckapi/api/2/v2/',
  //timeout: 5000
  timeout: 50 * 1000
})


const params0 = {
  token: 'chenksoft!@!',
  domain: `${domain.name}.chenksoft.com`
}

//请求拦截
//请求数据之前
instance.interceptors.request.use(
  function(config) {
    // console.group("全局请求拦截")
    // config.baseURL = 'https://hz.chenksoft.com/ckapi/api/3/v2/'
    // console.log(config)
    // console.groupEnd()
    //config.header.token = '12355'
    return config
  },
  function(err) {
    return Promise.reject(err);
  }
)
//响应拦截
//服务器返回数据之后
instance.interceptors.response.use(
  function(response) {
    //console.group("全局响应拦截")
    //console.log(response)
    //console.groupEnd()
    if(response.data.code == 5) {
      return Promise.reject('token错误');
    }else if(response.data.code == 8) {
      return Promise.reject('数据库操作异常');
    }
    return response
  },
  function(err) {
    return Promise.reject(err);
  }
)
//getvalue 因为静态数据在单位号2上
export function get2(url, params) {
  var params2 = {
    token: 'chenksoft!@!',
    langtype: 0
  }
  const Data = Object.assign({}, params2, params);
  return instance2.get(url, {
    params: Data
  });
}

export function get(url, params) {
  const Data = Object.assign({}, params0, params);

  //console.log("in getDomain url",url,"params",params);
  return instance.get(url, {
    params: Data
  });
}

export function post(url, data) {
  const Data = Object.assign({}, params0, data);
  return instance.post(url, Data)
}

export function imageFile (file) {
  if(!file){
    return '';
    //return img1;
  }else {
    const file0 = file.split(';')
    return  'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&domainid=3&fid='+file0[0]+'&filename='+file0[0];
  }
 
}