const host = window.location.host

let domain = {
  name: 'ams',
  basename: false,
  isHttps: false
};

if(host === 'sites.chenksoft.com') {
  domain = {
    name: window.location.pathname.replace(/\/$/g, '').split('/')[1],
    basename: true,
  }
}else if(host === 'localhost:8080'){
  domain.name = 'fds'
}else{
  domain.name = window.location.host.replace('www.','').replace('temp.','').split('.')[0]
  domain.basename = false
}
// if(document.location.protocol=='https:'){
//   domain.isHttps = true
// }else{
//   domain.isHttps = false
// }
export default domain