<template>
  <div class="our-news container-fluid4">
    <div class="left">
     <h2>{{news.title}}</h2>
     <ul class="header-line"><li><i class="el-icon-date"></i>{{news.time}}</li><li><i class="el-icon-edit"></i>晨科资讯</li></ul>
     <div v-html="news.content" class="new-content"></div>
     <ul class="footer-line">
       <li v-show="preNews.flag"><router-link :to="preNews.path"><span class="icon-box"><i class="el-icon-top"></i></span>{{preNews.title}}</router-link></li>
       <li v-show="nextNews.flag"><router-link :to="nextNews.path"><span class="icon-box"><i class="el-icon-bottom"></i></span>{{nextNews.title}}</router-link></li>
     </ul> 
    </div>
    <div class="right">
      <div class="deadline" v-show="newsListIndustry.length">
        <span class="title">行业新闻</span>
        <router-link to="/newcenter/information" class="more">
          <span >更多</span><img src="../assets/arrow-right.svg" alt="">
        </router-link>
      </div>
      <ul class="latest-pro">
        <li v-for="(item, index) in newsListIndustry" :key="index" class="left-img">
          <router-link :to="item.path">
            <img :src="item.img" alt="">
            <div class="text">
              <p class="img-text">{{item.title}}</p>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="deadline" v-show="cases.length">
        <span class="title">最新案例</span>
        <router-link to="/newcenter/news" class="more">
          <span >更多</span><img src="../assets/arrow-right.svg" alt="">
        </router-link>
      </div>
      <ul class="latest-pro">
        <li v-for="(item, index) in cases" :key="index">
          <div class="date-box">
            <p class="day">{{item.date}}</p>
            <p class="year">{{item.year}}</p>
          </div>
          <router-link :to="item.path">
            <p class="img-text">{{item.title}}</p>
          </router-link>
        </li>
      </ul>
     
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import '../style/text-content-html.scss'
import getQueryString from '../plugins/getQueryString.js'
import caseimg1 from '../assets/news-1.png'
import caseimg2 from '../assets/news-2.png'
import caseimg3 from '../assets/news-3.png'

export default {
  data(){
    return{
     relativeProducts: [],
     title: '资产管理系统解决方案',
     info:'',
     titleParams: [],
     path: '',
     casesImg: [caseimg1, caseimg2, caseimg3],
     cases:[],
     classicCases:[],
     news:{},
     preNews: { path:'',flag: false},
     nextNews: { path:'',flag: false},
     newsListIndustry:[]
    }
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    const languageId = sessionStorage.getItem('language')
    get('/index_article.jsp').then(function(data){
      const data0 = data.data.data
      const len = data0.length;

      for(var i=0; i<3; i++){
        const obj = {}
        obj.img = self.casesImg[i]
        var time = data0[i]['skt28.skf310'].split('-')
        obj.year = time[0]+'-'+time[1]
        obj.date = time[2]
        obj.path = '/newsdetail/'+data0[i]['skt28.skf303']
        obj.title = data0[i]['skt28.skf308']
        self.cases.push(obj)
      }
    })
    get('/select_hylb.jsp',{ number:0 }).then(function(data){
      const data0 = data.data.data
      const len = data0.length<3?data0.length:3;
      console.log(data0[0]['skt54.skf631'])
      for(var i=0; i<len; i++){
        const obj = {}
        obj.img = imageFile(data0[i]['skt54.skf631'])
        obj.time = data0[i]['skt54.skf626']
        obj.path = '/infodetail/'+data0[i]['skt54.skf619']
        obj.title = data0[i]['skt54.skf624']
        self.newsListIndustry.push(obj)
      }
      console.log(self.newsListIndustry)
    })
    get('/select_hyxq.jsp', {id:id}).then(function(res){
      const data0 = res.data.data;
      self.news.content = data0[0]['skt54.skf625'].replace(/size=\"4\"/g,'').replace(/font-size\: large;/g, '')
      self.news.time = data0[0]['skt54.skf626']
      self.news.title = data0[0]['skt54.skf624']
    })
    get('/select_hysyy.jsp', {id:id}).then(function(res){
     
      const data0 = res.data.data;
       if(data0.length){
        self.preNews.path = '/infodetail/'+data0[0]['a.id']
        self.preNews.title = data0[0]['a.title']
        self.preNews.flag = true
       }else{
         self.preNews.flag = false
       }
     
    })
    get('/select_hyxyy.jsp', {id:id}).then(function(res){
      const data0 = res.data.data;
      if(data0.length){
        self.nextNews.path = '/infodetail/'+data0[0]['a.id']
        self.nextNews.title = data0[0]['a.title']
        self.nextNews.flag = true
      }else{
        self.nextNews.flag = false
      }
     
    })
   
  }
}
</script>

<style lang="scss">
.our-news{
  background: #f8f8f8;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 0.75rem;
  line-height: 1.5;
  .left{
    display: inline-block;
    width: calc(98% - 17rem);
    background: #fff;
    vertical-align: top;
    padding: 2rem;
    .header-line{
      width: 100%;
      border-bottom: 1px solid #dedede;
      color: #666;
      margin-bottom: 4rem;
      li{
        display: inline-block;
        margin-right: 1rem;
        i{
          margin-right: 0.5rem;
        }
      }
    }
    .footer-line{
       width: 100%;
       border-top: 1px solid #dedede;
       margin-top: 4rem;
       padding-top: 0.5rem;
       a{
        color: #666;
        &:hover{
          text-decoration: underline;
          
        }
       }
       li{
         line-height: 2;
       }
      .icon-box{
        
        display: inline-block;
        
        margin-right: 1rem;
        color: #fff;
        i{
          width: 0.8rem;
          height: 0.8rem;
          font-weight: bold;
          background: #cacaca;
          &::before{
            vertical-align: middle;
          }
        }
      }
    }
    
    .new-content{
      line-height: 2;
      img{
        margin-top: 2rem;
        object-fit: contain;
        width: auto !important;
        height: auto !important;
      }
    }
    h2{
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .text-box{
      margin-bottom: 1rem;
      h4{
        font-size: 0.9rem;
        padding-bottom: 0.3rem;
        padding-top: 1rem;
        border-bottom: 1px dashed #dedede;
        margin-bottom: 1rem;
      }
      h5{
        font-size: 0.8rem;
        padding-bottom: 0.3rem;
      }
      img{
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        
      }
      .img-text{
        text-align: center;
      }
      .two-img{
        li{
          width: 49%;
          margin-right: 1%;
          display: inline-block;
        }
      }
    }
  }
  .right{
    width: 17rem;
    margin-left: 2%;
    display: inline-block;
    vertical-align: top;
    .deadline{
      padding: 1rem;
      background: #fff;
      // border-bottom: 1px solid #dedede;
      .title{
        font-size: 0.9rem;
      }
      .more{
        float: right;
        padding-top: 0.2rem;
        color:#666666;
        font-size:0.7rem;
        img{
          vertical-align: -6%;
        }
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .latest-pro{
      background: #fff;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      li{
        padding: 0.2rem 1rem;
        display: flex;
        align-items: center;
        a{
          width: 11rem;
           color: #666;
          &:hover{
            text-decoration: underline;
          }
        }
        &.left-img{
          a{
            width: 100%;
          }
          img{
            width: 110px;
            margin-right: 10px;
            border-radius: 4px;
          }
          .text{
            width: 180px;
            display: inline-block;
            vertical-align: top;
            &:hover{
              text-decoration: underline;
            }
            p{
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .date-box{
        width: 3.2rem;
        height: 3.2rem;
        background: #7B88A9;
        margin-right: 1rem;
        .day{
          font-size: 1.2rem;
          margin-top: 0.4rem;
          font-weight: bold;
          line-height: 1.2;
        }
        p{
          color: #fff;
          text-align: center;
          font-size: 0.7rem;
        }
      }
    }
    .relative-pro{
      background: #fff;
      li{
        padding:1rem;
        background: #fff;
        color: #666;
        .img-text{
          margin-bottom: 0.5rem;
        }
        .img-box{
          width: 100%;
          overflow:hidden;
          border-radius: 4px;
          img{
            width: 100%;
            &:hover{
              transform: scale(1.1);
              transition: transform 0.4s ease-in-out
            }
          }
        }
        
        p{
          color: #666666;
        }
        &:hover{
         p{
           text-decoration: underline;
         }
        }
      }
    }
  }
}
</style>