<template>
  <div class="products">
    <Header />
    <Banner />
    <MBanner />
    <Service1 />
    <Service2 />
    <Service3 />
    <ProductsList />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Service1 from '@/components/Service1.vue'
import Service2 from '@/components/Service2.vue'
import Service3 from '@/components/Service3.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    Footer,
    Service1,
    Service2,
    Service3,
    MBanner
  },
}
</script>

<style>

</style>