<template>
  <div class="hardware-item container-fluid3">
    <el-tabs v-model="activeName"  @tab-click="handleClick" type="card" id="fixed-nav" stretch>
      <el-tab-pane v-for="(item, index) in hardwares" :name="'tab'+index" :key="index" :label="item.title" class="container-fluid-fixed">
        <ul>
          <li :class="['item', {'flex-reverse': index2%2==0?false:true}]" v-for="(item2, index2) in item.item" :key="index2">
            <div class="text">
              <h2>{{item2.title}}</h2>
              <ul>
                <li v-for="(item3, index3) in item2.text" :key="index3"><i class="tick"></i><span>{{item3.subcontent}}</span></li>
              </ul>
            </div>
            <img :src="item2.img" alt="" class="hardware-image">
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <div class="nav"></div>
   
  </div>
</template>
<script>
import { get, imageFile } from '../plugins/request.js'

export default {
  data(){
    return {
      activeName: 'tab0',
      hardwares:[]
    }
  },
  created(){
    const self = this;
    get('/pro-yhfoot.jsp').then(function(res){
      console.log(res.data.data[0].result)
      const data0 =  JSON.parse(res.data.data[0].result)
      
      self.hardwares = data0.map((item, index) => {
        item.item.map((item2)=> {
          item2.img = imageFile(item2.img)
          return item2
        })
        return item
      })
      // console.log(self.hardwares)
    })
  },
  methods: {
    handleClick(){

    },
    watchScroll() {
      // 滚动的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      
      // 容器的高度
     
      var offsetTop = document.querySelector("#fixed-nav>.el-tabs__header").offsetTop
      const fixedHigh = document.querySelector("#fixed-nav").offsetTop
      //console.log(offsetTop)
      //判断window滚动条上下滚动
      const scrollDir = scrollTop - this.scroolI
      this.scroolI = scrollTop;
      //console.log(scrollTop,offsetTop,scrollDir,fixedHigh)
      //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if(scrollDir>0){
        if(scrollTop > offsetTop){
          document.querySelector("#fixed-nav>.el-tabs__header").classList.add("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop='65px'
        }else{
          document.querySelector("#fixed-nav>.el-tabs__header").classList.remove("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop=''
        }
        // this.navBarFixed = scrollTop > offsetTop ? true : false;
      }else{
        if(scrollTop > fixedHigh){
          document.querySelector("#fixed-nav>.el-tabs__header").classList.add("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop='65px'
        }else{
          document.querySelector("#fixed-nav>.el-tabs__header").classList.remove("navBarFixed")
          document.querySelector("#fixed-nav").style.paddingTop=''
        }
        //this.navBarFixed = scrollTop > fixedHigh ? true : false;
      }
   
    },
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  unmounted() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
}
</script>

<style lang="scss">

  .hardware-item{
    background: #F8F9FD;
    padding-top: 6rem;
    .item {
      padding: 2.5rem 7.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      background: #fff;
      border-radius: 6px;
      img{
        width: 400px;
      }
      &.flex-reverse{
        flex-direction: row-reverse;
      }
      .text{
        h2{
          font-size: 1.8rem;
          font-weight: normal;
          margin-bottom: 2rem;
          color: #444;
        }
        ul{
          li{
            font-size: 0.8rem;
            color: #666666;
            margin-bottom: 1rem;
            display: flex;
            span{
              line-height: 1.7;
              max-width: 320px;
              display: inline-block;
            }
            .tick{
              background: url('../assets/tick.png') no-repeat;
              width: 14px;
              height: 13px;
              display: inline-block;
              margin-right: 1rem;
              margin-top: 6px;
            }
          }
        }
      }
      .hardware-image{
        
      }
    }
    
    .navBarFixed{
      position: fixed;
      left:0;
      top: 0;
      z-index: 999;
      width: 100%;
    }
     .el-tabs__nav-next, .el-tabs__nav-prev{
      line-height: 3.4rem;
      font-size: 16px;
    }
    .el-tabs--card > .el-tabs__header{
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border: none;
      padding-right: 10%;
      padding-left: 10%;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item{
      border-left: none;
      font-size: 0.8rem;
      padding: 0 20px;
      height: 2.2rem;
      line-height: 2.4rem;
      border-radius: 1.5rem;
      transition: none;
    }
    .el-tabs__item.is-active{
      background-color: #235EB6;
      color: #fff;
    }
    
    .el-tabs__nav-wrap{
      background: #F8F9FD;
    }
    .el-tabs__nav-scroll{
      padding: 10px 0;
      background: #F8F9FD;
    }
    .container-fluid-fixed{
      max-width: 60rem;
      margin: 40px auto;
      padding: 1rem 1rem 2rem 1rem;
    }
    .el-tabs__content{
      margin-top: 2rem;
    }
    .el-tab-pane{
      //display: block !important;
    }
  }
</style>