<template>
  <div class="feature">
     <TitleInvSubtitle :title="title"/>
     <ul class="solutions-wrapper container-fluid3" v-if="!gmpFlag">
       <li class="solutions-items" v-for="(item, index) in data0" :key="index">
         <img :src="item.img" alt="">
         <div class="text">
           <p>{{item.title}}</p>
           <span>{{item.desc}}</span>
         </div>
       </li>
     </ul>
     <ul class="solutions-wrapper container-fluid3" v-else>
       <li class="solutions-items" v-for="(item, index) in data1" :key="index">
         <img :src="item.img" alt="">
         <div class="text">
           <p>{{item.title}}</p>
           <span>{{item.desc}}</span>
         </div>
       </li>
     </ul>
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import domain from '../plugins/getDomainName.js'

import icon1 from '../assets/网站-07.png'
import icon2 from '../assets/网站-08.png'
import icon3 from '../assets/网站-09.png'
import icon4 from '../assets/网站-10.png'
import icon5 from '../assets/网站-11.png'
import icon6 from '../assets/网站-12.png'

import gmpicon1 from '../assets/gmp--1.png'
import gmpicon2 from '../assets/gmp--2.png'
import gmpicon3 from '../assets/gmp--3.png'
import gmpicon4 from '../assets/gmp--4.png'
import gmpicon5 from '../assets/gmp--5.png'
import gmpicon6 from '../assets/gmp--6.png'
import gmpicon7 from '../assets/gmp--7.png'


export default {
 data(){
    return {
      title: {
        title: '产品功能、应用特点及优势',
        subtitle: ''
      },
      gmpFlag:false,
      languageId: 0,
      bannerDesc: '一个完全可扩展、灵活和安全的操作系统，实现根据客户个性化需求进行快速开发、敏捷定制，满足客户各种深度定制需求',
      waitMore: '',
      data0: [
        {'img':icon1, 'title':'消息协同', 'desc': '集成钉钉，企业微信，飞书等IM通讯软件，及时推送消息，进行协同处理'},
        {'img':icon2, 'title':'支持跨平台', 'desc': '支持Windows，Linux，Android，IOS，H5等平台，支持客户端，浏览器等'},
        {'img':icon3, 'title':'自定义扩展接口', 'desc': '预留标准业务接口，可自定义部署定制接口，易于集成三方系统'},
        {'img':icon4, 'title':'权限控制与加密', 'desc': '多角色业务权限管控，保证系统安全,数据采用多级加密，安全备份，防止数据丢失'},
        {'img':icon5, 'title':'跨地区，跨集团部署', 'desc': '支持集群服务、动态扩容、实时热备，支持大并发，大数据处理分析'},
        {'img':icon6, 'title':'支持多地协同办公', 'desc': '国际化，多语言支持，支持中文，英文，日文等其他语言'},
        ],
        data1: [
        {'img':gmpicon1, 'title':'打通线上线下流程', 'desc': '将药企GMP体系的文件—记录—档案—库房实现了全业务流程集成'},
        {'img':gmpicon2, 'title':'合规', 'desc': '系统执行的标准符合美国FDA21 CFR Part 11\/欧盟EMA GMP ANNEX 11\/GMP NMPA2010版'},
        {'img':gmpicon3, 'title':'专业', 'desc': '开发团队经验丰富—专业经验5年以上、实施团队经验丰富—实施行业项目7个以上、验证团队经验丰富—提供行业CSV服务10年以上'},
        {'img':gmpicon4, 'title':'服务', 'desc': '提供专业的GMP体系文件管理咨询、提供专业的驻厂实施服务不少于30天、提供专业的CSV验证服务、提供贴心的售后服务'},
        {'img':gmpicon5, 'title':'安全', 'desc': 'PSF安全架构，从数据加密、密码加密、用户认证、会话认证、访问授权、数据授权等6个方面确保系统安全'},
        {'img':gmpicon6, 'title':'可靠', 'desc': '建立GMP体系文件全生命周期数据链确保规范性和有效性、确保记录的打印受控\/复印受控\/数据可靠和可追溯、完整的日志文件确保数据的可追溯性'},
        {'img':gmpicon7, 'title':'价值', 'desc': '文件审批效率提高60%、记录管理效率提高80%、数据管理效率提高90%、迎检效率提高95%'},
        ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this;
    if(domain.name=="gmp"){
      this.gmpFlag = true
    }
  }
}
</script>

<style lang="scss">
.feature{
  .title-inv-subtitle{
    padding: 4rem 0;
  }
  .solutions-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .solutions-items{
      box-sizing: border-box;
      width: 42%;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      margin-bottom: 4rem;
      img{
        margin-right: 1.5rem;
      }
      .text{
        display: inline-block;
        vertical-align: top;
      }
      p{
        line-height: 1.6;
        font-size: 1rem;
        margin-bottom: 14px;
      }
      span{
        font-size: 0.7rem;
        line-height: 1.6;
        color:#666;
        display: inline-block;
      }
    }
  }
}

</style>