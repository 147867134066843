<template>
  <div class="our-function">
     <TitleInvSubtitle :title="title"/>
     <ul class="solutions-wrapper container-fluid3" v-if="data0.length">
       <li class="solutions-items" v-for="(item, index) in data0" :key="index">
         <img :src="item.img" alt="">
         <div class="text">
           <p>{{item.title}}</p>
           <span>{{item.desc}}</span>
         </div>
       </li>
     </ul>
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import { get,imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'
import img1 from '../assets/s-assets.png'

import icon1 from '../assets/h-icon01.png'
import icon2 from '../assets/h-icon02.png'
import icon3 from '../assets/h-icon03.png'
import icon4 from '../assets/h-icon04.png'
import icon5 from '../assets/h-icon05.png'
import icon6 from '../assets/h-icon06.png'
import icon7 from '../assets/h-icon07.png'
import icon8 from '../assets/h-icon08.png'
import icon9 from '../assets/h-icon09.png'
import axios from "axios"

export default {
 data(){
    return {
      title: {
        title: '',
        subtitle: ''
      },
      languageId: 0,
      bannerDesc: '一个完全可扩展、灵活和安全的操作系统，实现根据客户个性化需求进行快速开发、敏捷定制，满足客户各种深度定制需求',
      waitMore: '',
      data0: [],
      icon:[icon1,icon2,icon3,icon4,icon5,icon6,icon7,icon8,icon9]
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this;
   
     get('/index_func.jsp').then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      for(var i=0; i<len; i++){
        const obj = {}
        // obj.title = data0[i]['func_title']
        // obj.img = imageFile(data0[i]['func_logo'])
        // obj.desc = data0[i]['func_content']
        obj.title = data0[i]['skf112']
        obj.img = imageFile(data0[i]['skf114'])
        obj.desc = data0[i]['skf113']
        self.data0.push(obj)
      }
    })
    
    get('/index_func_title.jsp').then(function(data){
      self.title.title = data.data.data[0].index_func_title
    })

    //用于百度
    var logidUrl = window.location.href
    axios({
      method:'post',
      url:'https://hz.chenksoft.com/mixkit/jimuyu/eventupload',
      data: 'eventType=1&logidUrl='+logidUrl,
      headers: {
          contentType:'application/json'
      },
    }).then(function(res){
      console.log(res)
    })
  }
}
</script>

<style lang="scss">
.our-function{
  .title-inv-subtitle{
    padding: 4rem 0;
  }
  .solutions-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .solutions-items{
      box-sizing: border-box;
      width: 28%;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      margin-bottom: 4rem;
      img{
        margin-right: 1.5rem;
      }
      .text{
        display: inline-block;
        vertical-align: top;
      }
      p{
        line-height: 1.6;
        font-size: 1rem;
        margin-bottom: 14px;
      }
      span{
        font-size: 0.7rem;
        line-height: 1.6;
        color:#666;
      }
    }
  }
}

</style>