<template>
  <div class="h-cases-industry" v-show="industryItem.length">
    <div class="h-cases-bg"><img src="../assets/hcases-bg.jpg" alt=""></div>
    <div class="container-fluid5 posistion-absoulte">
      <div class="h-c-i-container">
        <div class="h-c-i-left">
          <div class="button"> <router-link to='/newcenter/news/'>查看更多案例</router-link> </div>
          <div class="content">
            <h2>{{caseListActive.title}}</h2>
            <p>{{caseListActive.desc}}</p>
            <div class="more">
              <router-link :to="'/newsdetail/'+caseListActive.id">
                <span>了解详情 </span>
                <i class="el-icon-arrow-right"></i>
              </router-link>
            </div>
          </div>
          <div>
            <ul class="view-item">
              <li :class="{'active':index==logoItemActive}" v-for="(item, index) in caseList" :key="index"><img :src="item.icon" alt="" @click="changeLogo(index)"></li>
            </ul>
            <div class="bar-line"></div>
          </div>
        </div>
        <div class="h-c-i-right">
          <h4 class="title">客户案例</h4>
            <p class='desc'>五千多成功客户案例，值得用户信赖</p>
          <ul class="industry-list">
            <li v-for="(item, index) in industryItem" :key="index" :class="{'margin-right': index%2==0,'active':index==industryItemActive}" @click="changeImg(item.id, index)">
              <div>
                <img :src="item.icon" alt="">
                <span>{{item.title}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get,imageFile } from '../plugins/request.js'

import icon1 from '../assets/h_icon_it1.png'
import hicon1 from '../assets/h_icon_it-b.png'
import icon2 from '../assets/h_icon_building.png'
import hicon2 from '../assets/h_icon_building-b.png'
import icon3 from '../assets/h_icon_company.png'
import hicon3 from '../assets/h_icon_company-b.png'
import icon4 from '../assets/h_icon_production.png'
import hicon4 from '../assets/h_icon_production-b.png'
import icon5 from '../assets/h_icon_logistics.png'
import hicon5 from '../assets/h_icon_logistics-b.png'
import icon6 from '../assets/h_icon_trade.png'
import hicon6 from '../assets/h_icon_trade-b.png'
import icon7 from '../assets/h_icon_medicine.png'
import hicon7 from '../assets/h_icon_medicine-b.png'
import icon8 from '../assets/h_icon_government.png'
import hicon8 from '../assets/h_icon_government-b.png'

export default {
  data(){
    return{
      industryItemActive:0,
      logoItemActive:0,
      industryItem: [],
      caseListActive:{},
      caseList: []
    }
  },
  created(){
    const self = this;
    get('/select_JXHY.jsp').then(function(data){
      const data0 = data.data.data
      const len = data0.length;
      if(len){
       for(var i=0; i<len; i++){
         const obj = {}
         obj.title = data0[i]['skt50.skf583']
         obj.icon = imageFile(data0[i]['skt51.skf592'])
         obj.id = data0[i]['skt51.skf590']
         self.industryItem.push(obj)
         
         if(i==0){
          get('/select_jxal.jsp',{id: obj.id}).then(function(data){
            const data1 = data.data.data
            const len1 = data1.length;

            if(len1){
              for(var j=0; j<len1; j++){
                const obj1 = {}
                obj1.title = data1[j]['skt52.skf600']
                obj1.icon = imageFile(data1[j]['skt52.skf601'])
                obj1.desc = data1[j]['skt52.skf602']
                obj1.id = data1[j]['skt52.skf611']
                self.caseList.push(obj1)
                if(j==0){
                  self.caseListActive = obj1
                }
              }
            }
          })
         }
       }
       
      }
    })
  },
  methods:{
    changeImg(index, activeIndex) {
      this.industryItemActive = activeIndex
      const self = this;
      self.caseList = []
      get('/select_jxal.jsp',{id: index}).then(function(data){
        const data1 = data.data.data
        const len1 = data1.length;
        if(len1){
          for(var j=0; j<len1; j++){
            const obj1 = {}
            obj1.title = data1[j]['skt52.skf600']
            obj1.icon = imageFile(data1[j]['skt52.skf601'])
            obj1.desc = data1[j]['skt52.skf602']
            obj1.id = data1[j]['skt52.skf611']
            self.caseList.push(obj1)
            if(j==0){
              self.caseListActive = obj1
            }
          }
        }
      })
    },
    changeLogo(index){
      this.logoItemActive = index
      this.caseListActive = this.caseList[index]
      console.log(this.caseListActive)
    }
  }
}
</script>

<style lang='scss'>
 .more-mobile{
    display: none;
  }
.h-cases-industry{
  position: relative;
  height: 700px;
  .h-cases-bg{
    img{
      width: 100%;
    }
  }
  .posistion-absoulte{
    position: absolute;
    top: 7rem;
  }
  .h-c-i-container{
    z-index: 3;
    background: #fff;
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    width: 100%;
    .h-c-i-left{
      // width: 800px;
      flex-grow:1;
      padding-top: 2rem;
      padding-bottom: 1rem;
      width: calc(100% - 465px);
      .button{
        width: 160px;
        height: 46px;
        background: #235EB6;
        border-radius: 0 23px 23px 0;
        font-size: 18px;
        color: #fff;
        line-height: 2.3rem;
        padding-left: 1rem;
        cursor: pointer;
        a{
          color: #fff;
        }
      }
      .content{
        padding: 3rem 2rem 2rem 3rem;
        color: #444;
        .more{
          a{
            color: #235EB6;
          }
          font-size: 16px;
          color: #235EB6;
          margin-top: 0.5rem;
          cursor: pointer;
          .el-icon-arrow-right{
            font-weight: 600;
          }
        }
        h2{
          font-size: 28px;
          font-weight: normal;
        }
        p{
          font-size: 16px;
          width: 100%;
          margin-top: 30px;
          line-height: 1.6;
          text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
        }
      }
      .view-item{
        background: #F4F4F4;
        padding-left: 3rem;
        padding-right: 3rem;
        position: relative;
        margin-top: 2rem;
        display: flex;
        //justify-content: space-between;
        li{
          margin-right: 1rem;
          cursor: pointer;
          position: relative;
          img{
            -webkit-filter: grayscale(1); /* Webkit */
            filter: gray; /* IE6-9 */
            filter: grayscale(1); /* W3C */
            opacity: 0.2;
            width: 100%;
          }
          &.active,&:hover{
            background: #fff;
           
            &::after{
              position: absolute;
              width: 100%;
              content:'';
              display: block;
              background: #235EB6;
              height: 4px;
              bottom: -14px;
            }
            img{
              filter: unset;
               opacity: 1;
            }
          }
        }
      }
      .bar-line{
        height: 0;
        width: 100%;
        background: #F4F4F4;
        margin-top: 10px;
      }
    }
    .h-c-i-right{
      width: 465px;
      background: #235EB6 url(../assets/hcase-bg-2.jpg) no-repeat;
      color: #fff;
      padding: 2rem 2rem 1rem 2rem;
      .title{
        font-size: 2rem;
        margin-bottom: 1.5rem;
        font-weight: normal;
      }
      .desc{
        font-size: 1.2rem;
        font-weight: 200;
      }
      .industry-list{
        margin-top: 3rem;
        li{
          width: 8.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          background: rgba(10,26,109,0.3);
          border-radius: 1.2rem;
          margin-bottom: 0.5rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          display: inline-block;
          cursor: pointer;
          font-size: 0.8rem;
          &.margin-right{
            margin-right:2rem;
            margin-bottom: 1.5rem;
          }
          &.active{
            background: #fff;
             color: #444;
             img{
                -webkit-filter: brightness(0);
                -moz-filter: brightness(0);
                -ms-filter: brightness(0);
                -o-filter: brightness(0);
                filter: brightness(0);
             }
          }
          img{
            vertical-align: -28%;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
</style>