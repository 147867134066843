<template>
  <div class="products">
     <Header />
     <Banner />
     <MBanner />
     <SiViewItem />
     <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import SiViewItem from '@/components/SiViewItem.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    Footer,
    SiViewItem,
    MBanner
  },
}
</script>

<style>

</style>