<template>
  <div>
     <Header />
     <Banner />
     <MBanner />
     <Cases1 />
     <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Cases1 from '@/components/Cases1.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import MBanner from '@/components/MBanner.vue'

export default {
  name: 'platform',
  components: {
    Header,
    Banner,
    Footer,
    Cases1,
    MBanner
  },
}
</script>

<style>

</style>