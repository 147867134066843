const COMPANY =[
  {
    "name": "电子",
    "company": [
      "上海湖碧驰精密仪器有限公司",
      "南京银茂微电子制造有限公司",
      "沃特赛恩电子技术有限公司",
      "杭州永灿光电技术有限公司",
      "北京京东方显示技术有限公司",
      "杭州三花先途电子有限公司",
      "上海线友电子有限公司南京办事处",
      "嘉联益科技(深圳)有限公司",
      "德国耶拿分析仪器股份公司北京代表处",
      "上海蕊恺电子",
      "日本电产三协电子（东莞）有限公司",
      "上海陈仁电器设备有限公司",
      "浙江大华技术股份有限公司",
      "上海震探电子科技有限公司",
      "思创医惠科技股份有限公司",
      "上海磊嘉机电有限公司",
      "上海佳安分析仪器厂",
      "达晟（上海）电子有限公司",
      "上海岳氏通讯科技有限公司",
      "上海市诺亚尊通讯器材商店岳国通",
      "杭州天目电力科技有限公司",
      "上海宝莱科技开发有限公司",
      "深圳市斯凯荣科技有限公司",
      "上海金毅实业有限公司",
      "二一三电器（上海）有限公司",
      "深圳市致尚微电子有限公司",
      "上海亚广展览",
      "上海伊碧思电子科技有限公司 ",
      "苏州英展电子有限公司",
      "山东优能电器设备有限公司",
      "上海软杰智能设备有限公司",
      "深圳市锐吉电子",
      "北京惠德豪仕科技发展有限公司",
      "上海穆诚实业有限公司",
      "上海索格电子有限公司",
      "北京奥特电子设备",
      "深圳市利佳盛科技有限公司",
      "北京捷世伟业电子科技有限公司",
      "浙江达世元电动科技有限公司",
      "上海朗苏实业有限公司",
      "星光电子科技有限公司",
      "上海依缇数码科技有限公司",
      "上海潘亚电子有限公司",
      "上海婓格电器有限公司",
      "大丰市蓝星科技有限公司",
      "睿网电子"
    ]
  },
  {
    "name": "金融",
    "company": [
      "桂林银行股份有限公司",
      "上海银行成都分行",
      "好利财富管理有限公司",
      "浴恒财富",
      "温州金融资产交易中心股份有限公司",
      "沈阳普华恒友互联网金融服务有限公司",
      "上海联保保险代理有限公司",
      "上海源丝投资发展有限公司",
      "浙江创能信息科技有限公司",
      "弛鑫金融",
      "南昌投资",
      "上海毅洋投资管理",
      "杭州人众资本管理有限公司",
      "东进投资管理有限公司",
      "浙江汇宽投资有限公司",
      "滨州一民资产管理有限公司",
      "深圳前海首控资产管理有限公司",
      "黄山海博投资管理有限公司",
      "乾笑控股有限公司",
      "无锡瑞轩投资有限公司",
      "乾笑控股有限公司",
      "众邦投资",
      "源泰财富",
      "厚元资产管理公司",
      "上海沪臣投资有限公司",
      "杭州人众资本管理公司",
      "济南源泰财富",
      "兆丰投资有限公司",
      "三六投资",
      "杭州人众金融服务外包有限公司",
      "舟山银企联动产质押服务有限公司",
      "华鑫",
      "腾辉财富管理",
      "杭州宏优体育投资管理有限公司",
      "兆丰投资有限公司",
      "江阴银辉投资管理有限公司",
      "博汇CRM",
      "南通海棠投资管理有限公司",
      "博铭投资",
      "顺轩投资谢立锋",
      "科发控股有限公司",
      "浙江人众金融服务外包有限公司",
      "上海黄金家族投资有限公司",
      "南通凯辉投资有限公司",
      "华融普银投资基金（北京）有限公司",
      "南通凯辉投资有限公司",
      "义乌市凯辉投资有限公司",
      "广东盈海贵金属经营有限公司义乌分公司",
      "旗威投资",
      "上海典金投资有限公司",
      "上海宝富典当有限公司",
      "奥熙投资",
      "奇益投资管理有限公司",
      "深圳前海首控资产管理有限公司",
      "东正金融",
      "世福资本股权投资管理（上海）有限公司",
      "驾亨通",
      "乾锐商业保理（上海）有限公司",
      "腾信堂投资管理咨询有限公司",
      "金华众人网络科技有限公司",
      "无锡银尚投资咨询有限公司",
      "温州凯辉投资有限公司",
      "深圳市艺华投资发展有限公司",
      "南华通科技中心",
      "沈阳金融商贸开发区正德科技小额贷款有限公司",
      "尚银投资咨询有限公司",
      "温州市凯辉投资有限公司",
      "杭州银线投资管理有限公司",
      "南通京氿投资管理有限公司",
      "深圳炎黄天兔实业有限公司",
      "汇盛投资管理(沈阳)有限公司",
      "人福电器有限公司"
    ]
  },
  {
    "name": "制造",
    "company": ["天赢智能",
    "上海安亭科学仪器厂",
    "西尼机电（杭州）有限公司",
    "上海大速电机有限公司",
    "大冢饮料",
    "顺昶塑胶（昆山）有限公司",
    "上海申安医疗器械厂",
    "上海瑞冉塑料制品有限公司",
    "安徽希玛安全防护科技有限公司",
    "上海湖碧驰精密仪器有限公司",
    "浙江大华技术有限公司",
    "东莞京滨汽车电喷装置有限公司",
    "二一三电器（上海）有限公司",
    "惠州艺都影像科技有限公司",
    "上海瑞冉塑料有限公司",
    "上海希玛鞋业",
    "嘉联益科技(深圳)有限公司",
    "上海翎贝实业有限公司",
    "上海华贡实业有限公司",
    "上海博隆粉体工程有限公司",
    "信耀电子",
    "武汉市自来水有限公司物资管理中心",
    "上海金亭汽车线束有限公司",
    "顺昶塑胶（天津）有限公司",
    "上海百集鞋业有限公司",
    "上海丹铨精密机械有限公司",
    "浩睿集團",
    "上海宝徕科技有限公司",
    "杭州鲁尔新材料科技有限公司",
    "桂辉电气(深圳)有限公司",
    "上海景好印务有限公司",
    "埃慕迪磁电科技（上海）有限公司",
    "上海意上实业有限公司",
    "上海斯可络压缩机有限公司",
    "上海金山区华发金属制品厂",
    "上海瑞年实业有限公司",
    "上海皎阳工具有限公司",
    "上海森迈木业有限公司",
    "南通沪望塑料科技发展有限公司",
    "博太科防爆设备（上海）有限公司",
    "上海富拓纸业有限公司",
    "上海劳瑞仪器设备有限公司",
    "上海新龙塑料制造有限公司",
    "上海博承商业道具有限公司",
    "瑞礼塑胶",
    "上海沪望建筑科技发展有限公司",
    "广州丰盛包装材料有限公司",
    "沈阳市金三星施封锁厂"]
  },
  {
    "name": "房地产",
    "company": [
      "鑫铭行",
    "深圳市光明侨新实业有限公司",
    "新力地产",
    "昆山鹿坊房地产发展有限公司",
    "上海嘉莹实业有限公司",
    "深圳市南山安居建设开发有限",
    "北京天宇恒远伟业房地产经纪有限公司",
    "深圳市大沙河建设投资有限公司",
    "云南吉兴隆房地产开发有限责任公司",
    "我爱我家",
    "深圳市林中杨实业有限公司",
    "嵊州市贝勤电子商务有限公司",
    "上海华石建设发展有限公司",
    "北京速优装饰有限公司",
    "房地产公司",
    "杭州明康工程咨询有限公司",
    "南通沪望塑料科技发展有限公司",
    "上海白领房产管理有限公司（众鑫白领公寓）",
    "上海众弘装饰建材有限公司",
    "南乐县宜家房产信息服务中心",
    "上海徐家汇商城有限公司",
    "沃棣家居设计咨询（上海）有限公司",
    "上海众弘装饰建材有限公司",
    "深圳联合共赢集团股份有限公司",
    "上海香墅建筑工程咨询有限公司",
    "湖北汇林建材有限公司",
    "北京博创机电设备安装有限公司",
    "北京龙鼎易居房地产经纪有限公司",
    "杭州筹房网科技股份有限公司",
    "绍兴华锦置业有限公司",
    "嵊州市佳业房产经纪有限公司",
    "上海玖尚商业管理有限公司",
    "上海万科房地产有限公司",
    "沈阳中沃置业有限公司",
    "嵊州佳业房产代理公司",
    "深圳联合共赢投资有限公司",
    "中润解放房产有限公司",
    "上海星浩房地产开发管理有限公司",
    "河南美商置业有限公司 ",
    "浙江绿郡恒业实业发展有限公司",
    "上海佳鹭新型建材有限公司",
    "中铁十一局集团第二工程有限公司",
    "四川和平卫士集团有限公司",
    "湖北长江路桥股份有限公司",
    "辽宁省交通科学研究院有限责任公司",
    "杭州宜路工程设计有限公司",
    "杭州梁筑供应链管理有限公司",
    "浙江明达意航控股集团"
    ]
  },
  {
    "name": "教育",
    "company": ["杭州航路教育咨询有限公司",
    "集美大学",
    "上海晓音文化传播有限公司",
    "深圳市美深文化传播有限公司",
    "杭州维思得英语培训学校",
    "上海金山区星加心文化艺术培训中心",
    "彭水苗族土家族自治县职业教育中心",
    "杭州云谷学校",
    "天津市凡学教育咨询有限公司",
    "上海徐汇区民办嘉宝幼儿园 ",
    "上海嘉璧教育科技有限公司",
    "深圳市深德技工学校",
    "上海深培投资发展有限公司",
    "浙江工业大学政治与公共管理学院",
    "北京桃李风华图书有限公司",
    "北京艾伯迪教育咨询有限公司",
    "浙江绿城育华教育科技有限公司",
    "北大成都附属实验学校",
    "赣州市易考教育科技有限公司",
    "辽宁浩博儿童游乐服务股份有限公司",
    "上海浦东新区民办嘉贝早教中心",
    "深圳大学光电所",
    "鱼乐贝贝婴幼儿水育馆",
    "深圳龙华区金博士培训中心",
    "杭州尤今文化创意有限公司",
    "深圳粤新华培训",
    "上海体适能体育管理有限公司",
    "杭州淼蓝科技有限公司",
    "上海星河湾双语学校（维护）",
    "勤路教育有限公司",
    "云南泰晤士教育信息咨询有限公司",
    "上海清晖管理咨询有限公司",
    "道勤教育",
    "上海体适能体育管理有限公司",
    "上海正谦文化传播有限公司  ",
    "深圳金色田园科技股份有限公司",
    "北京师友联盟文化传播有限公司",
    "人达文化艺术学校",
    "上海真己化人教育科技有限公司君学中国",
    "都江堰市南山中学实验学校",
    "青海锦帆信息科技有限公司",
    "玖零互生投资（北京）股份有限公司",
    "上海天奎投资管理有限公司",
    "简思商务咨询（上海）有限公司",
    "International Centre for EAP(ICEAP)",
    "内蒙古通辽库伦旗第一中学",
    "宁波大学",
    "广东省为众消防安全职业培训学校",
    "Auspac International Pty Ltd.",
    "北京爱尔惠母科技发展有限公司",
    "大连高新区立智管理培训学校",
    "杭州市华业职业培训学校",
    "浙大作物研究所",
    "上海凯顿培训学校",
    "杭州西湖职业培训集团",
    "中国石油和化工勘察设计协会",
    "杭州萧山萧报教育咨询有限公司",
    "上海浦华文化传播有限公司",
    "大连博佳文化传播有限公司",
    "上海巴伐利亚职业技能培训学校",
    "沈阳金色童年游乐玩具制造有限公司",
    "杭州维思得英语培训",
    "上海交通大学",
    "杭州多赢教育咨询有限公司",
    "沈阳歆萌文化交流有限公司(乐鼠)",
    "沈阳盈科教育咨询有限公司",
    "上海思之蕊文化艺术有限公司迅阳教育",
    "上海善言企业管理咨询有限公司",
    "沈阳思优佳教育信息咨询有限公司",
    "杭州西湖职业培训集团",
    "深圳市爱派斯文化发展有限公司",
    "杭州锦阳专修学校",
    "上海希敖文化传播有限公司",
    "卓博教育集团",
    "杭州音之舞教育科技有限公司",
    "连云港广通网络工程有限责任公司",
    "辽宁政法职业技术学院",
    "大连博佳机器人俱乐部",
    "浙江电信培训中心",
    "浙江大学继续教育学院",
    "杭州美谷科技有限公司",
    "上海凡响教育培训有限公司",
    "上海埃赛士体育咨询有限公司",
    "南京石头英语培训",
    "南京旅游职业学院",
    "上海弘毅教育培训有限公司",
    "上海浦江商务专修学院",
    "弘毅国学",
    "上海容大教育培训有限公司",
    "上海菲索幼儿园",
    "深圳小主人文化传播有限公司",
    "上海新儒商文化发展有限公司",
    "上海蒙特梭利投资管理有限公司",
    "上海屹诺体育策划有限公司(屹磊空手道)",
    "上海金量企业管理顾问有限公司",
    "武汉市田园之星教育咨询有限公司",
    "上海和仲企业发展有限公司",
    "浙大继续教育学院",
    "上海复新进修学院",
    "上海黄浦区梵帝斯进修学校",
    "深圳菲菲美容美发培训学校",
    "深圳市助力科技有限公司",
    "广州中九教育咨询有限公司深圳分公司",
    "重庆帝艺佳教育咨询有限公司",
    "天津滨海创联教育咨询服务有限公司",
    "嘉华世达国际教育交流有限公司 上海办事处",
    "深圳亚马逊历奇教育咨询有限公司",
    "美国视博恩公司北京代表处",
    "湖北华政英才教育咨询有限公司",
    "上海星河湾双语学校",
    "思源海外培训",
    "金得林（上海）企业管理咨询有限公司",
    "上海巨海企业管理培训有限公司",
    "明珠中英文小学",
    "上海美吉姆文化传播有限公司",
    "上海华彰教育信息咨询有限公司",
    "天津滨海创联教育咨询服务有限公司",
    "上海跃勤信息技术有限公司淘课培训 ",
    "东方教具",
    "上海修庸居休闲健身有限公司",
    "上海市莘庄工业区文化体育发展中心",
    "浙江师范大学出国留学服务中心",
    "易成教育",
    "天津滨海创联教育咨询服务有限公司",
    "金色年华体育舞蹈培训学校",
    "上海铭驭体育策划有限公司"]
  },
  {
    "name": "IT服务",
    "company": [
      "杭州天目电力科技有限公司",
    "杭州西子通讯有限公司",
    "浙江电信培训中心",
    "杭州亿维知识产权代理有限公司",
    "上海沁银企业服务有限公司 ",
    "杭州杰商网络科技有限公司",
    "杭州任意门电子商务有限公司",
    "北京康拓科技有限公司",
    "杭州晶佰生物技术有限公司",
    "北京云杉世界信息技术有限公司",
    "上海安可科技有限公司",
    "北京展创科技有限公司",
    "北京惠德豪仕科技发展有限公司",
    "杭州康司德科技有限公司",
    "上海颐绅网络科技有限公司",
    "萧山茜子通讯服务部",
    "上海望亭商贸有限公司",
    "四海恒源（北京）科技有限公司",
    "上海沃蓝集团",
    "上海赋运信息技术有限公司",
    "浙江中融数据技术有限公司",
    "沈阳旭宏升通信信息有限公司",
    "杭州春语网络技术有限公司",
    "浙江老年报报业有限公司",
    "深圳耳聪助听器有限公司",
    "金淘网络科技有限公司",
    "上海阿敏商务咨询有限公司",
    "广州中海电信",
    "奥尼斯特人力资源服务公司",
    "浙江城建煤气热电设计院有限公司",
    "临平天目科技有限公司",
    "上海中商网络股份有限公司"
    ]
  },
  {
    "name": "服装",
    "company": [
      "洛兹服饰",
    "深圳市宜与尚服装有限公司",
    "杭州华升服装有限公司",
    "杭州黯涉电子商务有限公司",
    "上海希玛鞋业",
    "武汉市子连纺织品有限公司",
    "上海天裁实业有限公司",
    "上海君希鞋业有限公司",
    "上海天裁实业有限公司",
    "海盐县于城丝绸印染有限公司",
    "上海服贸因私出入境服务有限公司",
    "沈阳市三耳兔服饰有限公司",
    "思博服饰有限公司",
    "杭州舞之美"
    ]
  },
  {
    "name": "管理咨询",
    "company": [
      "上海巨海企业管理顾问有限公司",
    "上海徐家汇商城有限公司",
    "杭州国益路桥经营管理有限公司",
    "上海海派城市经济发展规划研究院",
    "杭州赛冠人力资源有限公司",
    "爱唯尔（上海）企业发展有限公司",
    "上海依多企业管理有限公司",
    "上海清晖管理咨询有限公司",
    "深圳市立正管理咨询有限公司",
    "华纳鸿业建筑设计咨询（北京）有限公司",
    "上海善言企业管理咨询有限公司",
    "超盈人力资源",
    "上海天智双赢企业管理咨询有限公司",
    "上海晏清企业管理中心（有限合伙）",
    "上海卡碧恩企业咨询管理有限公司",
    "杭州亿维知识产权代理有限公司",
    "义乌巨顺投资管理有限贵公司",
    "滨州市万嘉盈信息咨询有限公司",
    "深圳市中弘传智咨询有限公司",
    "北京布谷品牌形象设计有限公司",
    "征辉资产管理公司",
    "衢州市交通设计有限公司",
    "上海埃赛士体育咨询有限公司",
    "浙江申铁建设工程设计咨询有限公司",
    "中智沈阳经济技术合作有限公司",
    "上海和仲企业发展有限公司",
    "上海平大建筑工程管理咨询有限公司",
    "上海资恒财务咨询有限公司",
    "中金汇通有限公司",
    "上海领航企业管理咨询有限公司",
    "华业鼎盛（北京）投资担保有限公司",
    "杭州道勤企业管理咨询有限公司",
    "上海翼展风迎企业管理咨询有限公司",
    "上海引腾企业管理有限公司",
    "上海威训企业咨询有限公司",
    "上海毅强企业管理咨询有限公司",
    "阖兢企业管理咨询（上海）有限公司",
    "金得林（上海）企业管理咨询有限公司",
    "上海容基",
    "无锡倚天富远投资咨询有限公司",
    "金成钢铁网",
    "上海富鑫咨询公司",
    "金泰钢价咨询公司",
    "上海阿敏商务咨询有限公司",
    "杭州市来客商务咨询有限公司"
    ]
  },
  {
    "name": "医药",
    "company": [
      "上海富科思分析仪器有限公司",
    "上海依畅医疗科技股份有限公司",
    "四川利尔作物科学有限公司",
    "湖南科伦药业股份有限公司岳阳分公司 ",
    "河南科伦药业股份有限公司",
    "深圳华大基因生命科学研究院",
    "广东暨大基因药物工程研究中心有限公司",
    "杭州中美华东制药有限公司",
    "上海万瑞斯泰医疗科技有限公司",
    "上海申安医疗器械厂",
    "上海信销信息科技有限公司",
    "辽宁冠男医药有限责任公司",
    "浙江华东医药股份有限公司",
    "杭州正熙生物科技有限公司",
    "国仁医疗科技有限公司",
    "浙江京新药业有限公司",
    "上海风眼网络技术有限公司",
    "上海睿昂生物技术有限公司",
    "上海中信国健药业股份有限公司",
    "上海瑞东医院",
    "阿斯利康投资（中国）有限公司",
    "百慧医疗",
    "上海医慧医疗科技有限公司",
    "杭州鲁尔新材料科技有限公司",
    "上海道生医疗科技有限公司",
    "杭州拱墅江南康复医院",
    "东直门医院",
    "济南六一医疗",
    "浙江老年报报业有限公司",
    "杭州怡生堂大药房连锁有限公司",
    "上海销信信息技术有限公司",
    "上海鸿慈儿童医院",
    "台州清泉医药股份有限公司",
    "上海甄逸医院投资管理有限公司康威门诊",
    "上海臻景门诊部有限公司",
    "上海济能医药科技有限公司",
    "上海臻景医院管理有限公司",
    "杭州利百乐医药有限公司",
    "上海杏康投资管理有限公司",
    "上海吉晨卫生后勤服务管理有限公司"
    ]
  },
  {
    "name": "政府机构",
    "company": [
      "上海闵行区梅陇镇陇西经济合作社",
    "中车贵阳车辆有限公司",
    "杭州社会福利中心",
    "中国棉纺织行业协会",
    "海洋二所",
    "浙江广播电视集团",
    "临安人民法院",
    "中央储备粮",
    "重庆市公安局江北区分局",
    "上海市土地交易事务中心",
    "上海市浦东新区城管执法局执法支队",
    "六合区公路管理站",
    "杭州市人民防空办公室",
    "浙江义乌中国小商品城广告有限责任公司",
    "中国林科院亚热带研究所",
    "北京市海淀区绿化队",
    "中国城市燃气协会分布式能源专业委员会",
    "浙江广播电台经济频道",
    "闸北公安分局"
    ]
  },
  {
    "name": "物流",
    "company": [
      "上海劲添国际货运代理有限公司",
    "上海龙渤实业发展有限公司",
    "驹马集团",
    "深圳鑫逸和集装箱服务有限公司",
    "深圳珉丰集装箱服务有限公司",
    "北方冷藏物流有限公司",
    "杭州鲁尔新材料科技有限公司",
    "增益冷链（武汉）有限公司",
    "中邮物流",
    "沈阳枢纽汽车物流有限公司铁岭分公司",
    "余干县晋通达物流有限责任公司",
    "上海龙赛物流有限公司",
    "杭州润缘信息科技有限公司",
    "上海安威物流有限公司",
    "深圳市德源农产品配送有限公司",
    "深圳市鹏隆成实业发展有限公司",
    "辽宁华通物流有限公司第一分公司",
    "辽宁诚通物流有限公司",
    "上海联腾物流有限公司",
    "上海明杉信息科技有限公司",
    "上海弘皓物流有限公司",
    "中国东方航空股份有限公司",
    "上海善运物流有限公司",
    "深圳棋洋国际物流有限公司",
    "武汉石化交通运行公司",
    "北京京田通物流",
    "海口南青集装箱班轮有限公司上海分公司",
    "上海丝翼航空技术有限公司",
    "九江中船长安消防设备有限公司上海分公司 ",
    "温岭大运物流",
    "桐庐大运物流"
    ]
  },
  {
    "name": "汽车",
    "company": [
      "浙江印象汽车服务有限公司",
    "浙江新世纪汽车销售服务有限公司",
    "上海东昌汽车售后服务有限公司",
    "嘉兴市百和汽车有限公司",
    "浙江森凯汽车",
    "爱孚迪（上海）制造系统工程有限公司",
    "嘉兴之远汽车销售服务有限公司",
    "嘉兴市思源汽车有限公司",
    "平湖市百悦汽车有限公司",
    "浙江康桥康嘉汽车有限公司",
    "桐乡博众汽车销售有限公司",
    "平湖市百恒汽车有限公司",
    "桐乡市中顺汽车销售服务有限公司",
    "上海鸿途汽车销售服务有限公司",
    "嘉兴君诺汽车销售服务有限公司",
    "嘉兴市百胜汽车有限公司",
    "武汉友芝友集团广乘4S店曹经理",
    "上海宝呈汽车服务有限公司曹庆伦",
    "南京高田汽车销售服务",
    "嘉善爱众汽车销售服务有限公司",
    "上海力盛赛车文化股份有限公司",
    "海宁市泽宇汽车销售服务有限公司",
    "鞍钢汽车运输公司",
    "上海势威（汽车）贸易有限公司",
    "上海格林威汽车销售有限公司",
    "格林威（昆山）汽车销售服务器有限公司",
    "沈阳市利丰源达汽车销售服务有限公司",
    "百仕特",
    "江苏雨田广宏汽车销售服务有限公司",
    "上海乐驰汽车维修有限公司"
    ]
  }
]

let _allTable = [];

// for(var key in COMPANY) {
//   let _table = [];
//   if(COMPANY[key].length%3!=0) {
//     const num = 3-COMPANY[key].length%3;
//     for(var i=0; i<num; i++) {
//       COMPANY[key].push('')
//     }
//   }
//   COMPANY[key].forEach(function(item, index){
//     if(index%3==0) {
//       _table.push(COMPANY[key].slice(index, index+3))
//     }
//   })
//   _allTable[key] = _table;
// }

COMPANY.forEach(element => {
  let _table = {
    name: element.name,
    company: []
  };
  if(element.company.length%3!=0) {
    const num = 3-element.company.length%3;
    for(var i=0; i<num; i++) {
      element.company.push('')
    }
  }
  element.company.forEach(function(item, index){
    if(index%3==0) {
      _table.company.push(element.company.slice(index, index+3))
    }
  })
  _allTable.push(_table)
});

export default {original: COMPANY, deal: _allTable}
