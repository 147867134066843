export const AREA = [
  {
    area: '华东地区',
    list: [
      {
        city: '杭州（总部）',
        address: '浙江省杭州市西湖区申花路789号剑桥公社F座1107室',
        tel: '400-6990-220'
      },
      {
        city: '上海',
        address: '上海市浦东新区灵岩南路295号三林世博创意园17栋303A',
        tel: '400-6990-220'
      },
      {
        city: '南京',
        address: '江苏南京市尧化新村 100号35-2-401',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '华中地区',
    list: [
      {
        city: '武汉',
        address: '武汉市洪山区文化大道555号融创智谷工业项目（一期）C8-3-601室',
        tel: '400-6990-220'
      },
      {
        city: '郑州',
        address: '郑州市二七区升龙世纪花园二区6号楼1单元',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '华南地区',
    list: [
      {
        city: '广州',
        address: '广州市荔湾区桥中街花语水岸8栋1103房',
        tel: '400-6990-220'
      },
      {
        city: '深圳',
        address: '深圳市龙华区龙华街道三联社区富联一区一巷 OTO创客商务中心217',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '华北地区',
    list: [
     
      {
        city: '北京',
        address: '北京市亦庄经济技术开发区BDA企业大道57号楼1712',
        tel: '400-6990-220'
      },
      {
        city: '青岛',
        address: '青岛市市北区标山路67号2-1704',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '西北地区',
    list: [
      {
        city: '西安',
        address: '西安市荣民时代公馆1幢2单元22402室',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '西南地区',
    list: [
      {
        city: '成都',
        address: '成都市金牛区花照壁西顺街388号紫宸香颂四期5栋1单元510室',
        tel: '400-6990-220'
      }
    ]
  }
]